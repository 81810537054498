import axios from 'axios';
import Api from './Api';

const LOCAL_STORAGE_USER_ID = 'user_id';
const LOCAL_STORAGE_TOKEN = 'token';
const LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

export default {
  setTokens: function(data) {
    this.setToken(data.token);
    this.setRefreshToken(data.refresh_token);
    this.setUserId(data.user.id);
  },
  setToken: function(token) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
  },
  setRefreshToken: function(refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  },
  setUserId: function(userId) {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, userId);
  },
  getToken: function() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN);
  },
  getRefreshToken: function() {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
  },
  getUserId: function() {
    return localStorage.getItem(LOCAL_STORAGE_USER_ID);
  },
  clear: function() {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_USER_ID);
  },
  isAuthenticated: function() {
    return this.getToken() !== null;
  },
  getNewToken: function() {
    return new Promise((resolve, reject) => {
      Api.post('users/refresh_token', {
        user_id: this.getUserId(),
        refresh_token: this.getRefreshToken()
      }).then(response => {
        this.setToken(response.data.token);
        this.setRefreshToken(response.data.refresh_token);

        resolve(response.data.token);
      }).catch(error => {
        reject(error);
      })
    });
  }
}