import React, { useState, useEffect } from 'react';
import { Redirect, Route, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import Api from '../../services/Api';
import PageRoutes from '../../services/PageRoutes';
import TokenStorage from '../../services/TokenStorage';
import ContentLoader from './ContentLoader';

function AuthRoute(props) {
  const [authInfo, setAuthInfo] = useState({ loading: true, authorized: false, user: {} });
  const { component: Component, ...rest } = props;

  useEffect(() => {
    let cancel = false;

    const checkAuth = async () => {
      if(TokenStorage.isAuthenticated()) {
        var token = TokenStorage.getToken();
  
        if(token) {
          const { exp } = jwt_decode(token);
          let expired = false;

          if (exp < (new Date().getTime() + 1) / 1000) {
            expired = true;
          }

          if(expired) {

            try {
              const { data: refresh_data } = await Api.post('users/refresh_token', { 
                user_id: parseInt(TokenStorage.getUserId()), 
                refresh_token: TokenStorage.getRefreshToken() 
              });
              const { data: user_data } = await Api.get('users/me');

              TokenStorage.setToken(refresh_data.token);
              TokenStorage.setRefreshToken(refresh_data.refresh_token);

              setAuthInfo({
                loading: false,
                authorized: true,
                user: user_data.user
              })


            } catch(error) {
              console.log('refresh error', error)
            }
          } else {
            try {
              const { data: user_data } = await Api.get('users/me');

              setAuthInfo({
                loading: false,
                authorized: true,
                user: user_data.user
              });
            } catch(error) {
              console.log('auth error', error)
            }
          }
        }
      }
    }
    
    checkAuth();

    return () => {
      cancel = true;
    }
  }, []);

  return (
    <Route path={props.path}
      render={props => authInfo.authorized ? ( <Component {...props} user={authInfo.user} /> ) : ( authInfo.loading ? (<ContentLoader />) : (<Redirect to={PageRoutes.Login} />))}
    />
  )
}

export default AuthRoute