import PageRoutes from "../../services/PageRoutes";
import TokenStorage from "../../services/TokenStorage";

export default {
  getParameterization(path, fields) {
    var finalPath = path;

    Object.keys(fields).forEach(function(key) {
      if(key === '*') {
        finalPath = finalPath.replace('*', fields[key])
      } else {
        var regex = RegExp(key, 'g');
        finalPath = finalPath.replace(regex, fields[key]);
      }
    });

    return finalPath;
  },
  getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  },
  getDocumentTypeId(types, name) {
    if(types) {
      let docType = types.filter((item) => item.name === name);
      if(docType.length == 1) {
        return docType[0].id;
      }
    }
   
    return -1;
  },
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  getName(props) {
    let fullName = '';

    if(props.user) {
      fullName = `${props.user.first_name} ${props.user.last_name}`;
    }

    return fullName;
  },
  logOut() {
    TokenStorage.clear();
    window.location = PageRoutes.Login;
  }
}