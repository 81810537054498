import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card, 
  Typography,
  notification,
} from 'antd';
import { ExportOutlined, UnlockOutlined } from '@ant-design/icons';
import axios from 'axios';
import Utils from '../common/Utils';
import PageRoutes from '../../services/PageRoutes';

const { Text } = Typography;

function ForgotPassword(props) {
  const [email, setEmail] = useState('');

  const performReset = () => {
    if(email && email.trim().length > 0) {
      if(Utils.validateEmail(email)) {
        axios.post(`${process.env.REACT_APP_API_URL}users/forgot_password`, {
          email: email
        })
        .then(res => {
          notification.success({
            message: 'Forgot Password Sent',
            description: 'We have emailed you the instructions on how to reset your password.',
            placement: 'bottomRight',
            duration: 2,
            onClose: () => {
              props.history.replace(PageRoutes.Login);
            },
            onClick: () => {
              props.history.replace(PageRoutes.Login);
            }
          });
        })
        .catch(error => {
          console.log('error', error);
          notification.error({
            message: 'Forgot Password Error',
            description: 'The email appears to be invalid or may not exist on the system. Please update the email address and try again.',
            placement: 'bottomRight'
          });
        })
      } else {
        notification.error({
          message: 'Forgot Password Error',
          description: 'The email appears to be an incorrect format. Please update the email address and try again.',
          placement: 'bottomRight'
        });
      }
    }
  }
  
  return (
    <div style={{backgroundColor: '#eee'}}>
      <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
        <Col span={4}>
          <Card
            style={{ width: 400, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "5px 8px 24px 5px rgba(0, 0, 0, 0.1)" }}
            actions={[
              <Text onClick={() => props.history.push(PageRoutes.Login)}><ExportOutlined key="forgot_password" style={{paddingRight: 10}} /> Sign In</Text>,
            ]}
          >
            <Row type="flex" justify="center" style={{paddingTop: 20}}>
              <Col>
                <img
                  width="200"
                  alt="GEA"
                  src="/assets/gea_logo_login.jpg"
                />
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col>
                <Text type="secondary">Hygiene App Administration</Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Text type="primary" strong={true}>Forgot Password</Text>
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col>
                <Text type="secondary">To reset your password, please enter the email you registered with.</Text>
              </Col>
            </Row>
            <Form layout={"vertical"} style={{marginTop: 20}}>
              <Form.Item
                style={{marginBottom: 0, paddingBottom: 10}}
                colon={false}
                label="Enter email address">
                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="button" style={{borderRadius: 5}} onClick={() => performReset()} block>
                  RESET PASSWORD
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ForgotPassword
