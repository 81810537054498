import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './index.less';
import 'react-quill/dist/quill.snow.css';

import PageRoutes from './services/PageRoutes';

import AuthRoute from './components/common/AuthRoute';
import RouteNotFound from './components/common/RouteNotFound';

import Login from './components/public/Login';
import Landing from './components/portal/Landing';
import ActiveIngredients from './components/portal/settings/ActiveIngredients';
import Brands from './components/portal/settings/Brands';
import Filters from './components/portal/settings/Filters';
import Categories from './components/portal/settings/Categories';
import SubCategories from './components/portal/settings/SubCategories';
import Countries from './components/portal/settings/Countries';

import Products from './components/portal/products/ListProducts';
import CreateProduct from './components/portal/products/CreateProduct';
import EditProduct from './components/portal/products/EditProduct';

import ListUsers from './components/portal/users/ListUsers';
import CreateUser from './components/portal/users/CreateUser';
import EditUser from './components/portal/users/EditUser';

import ReportsDashboard from './components/portal/reports/ReportsDashboard';
import ListFarmers from './components/portal/farmers/ListFarmers';
import CreateFarmer from './components/portal/farmers/CreateFarmer';
import EditFarmer from './components/portal/farmers/EditFarmer';

import ForgotPassword from './components/public/ForgotPassword';
import ResetPassword from './components/public/ResetPassword';
import Activate from './components/public/Activate';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path={PageRoutes.Login} component={Login} />
      <Route exact path={PageRoutes.ForgotPassword} component={ForgotPassword} />
      <Route exact path={PageRoutes.ResetPassword} component={ResetPassword} />
      <Route exact path={PageRoutes.Activate} component={Activate} />

      <AuthRoute exact path={PageRoutes.Landing} component={Landing} />
      <AuthRoute exact path={PageRoutes.ActiveIngredients} component={ActiveIngredients} />
      <AuthRoute exact path={PageRoutes.Brands} component={Brands} />
      <AuthRoute exact path={PageRoutes.Filters} component={Filters} />
      <AuthRoute exact path={PageRoutes.Categories} component={Categories} />
      <AuthRoute exact path={PageRoutes.SubCategories} component={SubCategories} />
      <AuthRoute exact path={PageRoutes.Countries} component={Countries} />

      <AuthRoute exact path={PageRoutes.Products} component={Products} />
      <AuthRoute exact path={PageRoutes.ProductCreate} component={CreateProduct} />
      <AuthRoute exact path={PageRoutes.ProductEdit} component={EditProduct} />

      <AuthRoute exact path={PageRoutes.Users} component={ListUsers} />
      <AuthRoute exact path={PageRoutes.UserCreate} component={CreateUser} />
      <AuthRoute exact path={PageRoutes.UserEdit} component={EditUser} />

      <AuthRoute exact path={PageRoutes.Farmers} component={ListFarmers} />
      <AuthRoute exact path={PageRoutes.FarmerCreate} component={CreateFarmer} />
      <AuthRoute exact path={PageRoutes.FarmerEdit} component={EditFarmer} />

      <AuthRoute exact path={PageRoutes.Reports} component={ReportsDashboard} />

      <Route component={RouteNotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);