import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, notification, Popconfirm, Row, Skeleton, Table } from 'antd';
import MainLayout from '../../common/MainLayout';
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import AddDialog from '../../forms/AddDialog';
import Api from '../../../services/Api';
import Utils from '../../common/Utils';
import TokenStorage from '../../../services/TokenStorage';

function Filters(props) {
  const [showAdd, setShowAdd] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    let cancel = false;
    
    retrieveFilters();

    return () => {
      cancel = true;
    }
  }, []);

  const retrieveFilters = async () => {
    try {
      const { data } = await Api.get('filters/list');
      setFilters(data.filters);
    } catch(error) {

    }
  }

  const deleteRow = async (rowId) => {
    try {
      await Api.delete(`filters/${rowId}/delete`);

      const newFilters = [...filters];
      newFilters.splice(newFilters.findIndex(item => item.id === rowId), 1)
      setFilters(newFilters)
    } catch(error) {
      notification.error({
        message: 'Filter Error',
        description: 'There was a problem deleting this filter. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Button type="default" size="small" onClick={() => updateRow(row)}>
              <EditOutlined /> 
            </Button>
            <Popconfirm placement="right" title={'Are you sure you want to delete this filter?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            {
              row.icon_url ? <img src={`${process.env.REACT_APP_API_URL}documents/download/${row.icon_url}?t=${new Date().getTime()}&access_token=${TokenStorage.getToken()}`} alt="" width={40} height={40} /> : <Skeleton.Image style={{width: 40, height: 40}} />
            }
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
  ];

  const updateRow = (item) => {
    setSelectedItem(item);
    setShowAdd(true);
  }

  const handleCreateNew = () => {
    setSelectedItem(null);
    setShowAdd(true);
  }

  const handleAddOnClick = async (type, value, item, files, translations) => {
    const translationDictionary = {};

    translations && translations.forEach(item => {
      translationDictionary[item.language] = item.translation;
    });

    if(type === 'cancel') {
      setShowAdd(false);
    } else if(type === 'add') {
      const { data } = await Api.post('filters/create', { name: value, name_translations: translationDictionary });
     
      if(data && data.id > 0) {

        if(files.length > 0) {
          files.map(async (filterItem) => {
            var img = URL.createObjectURL(filterItem);
            fetch(img)
            .then(async (res) => res.blob())
            .then(async (blob) => {
              var options = {
                method: 'POST',
                headers: {
                  "Api-Arg": JSON.stringify({
                    name: filterItem.name,
                    file_type: Utils.getFileExtension(filterItem.name)
                  }),
                  "Content-Type": filterItem.type,
                  "Authorization": `JWT ${TokenStorage.getToken()}`
                },
                data: blob,
                url: `${process.env.REACT_APP_API_URL}filters/${data.id}/upload_icon`
              }
    
              try {
                await axios(options);

                notification.success({
                  message: 'Filter Success',
                  description: 'The filter has been created successfully!',
                  placement: 'bottomRight'
                });
                setShowAdd(false);
                retrieveFilters();
              } catch(error) {

              }
            });
          });
        } else {
          notification.success({
            message: 'Filter Success',
            description: 'The filter has been created successfully!',
            placement: 'bottomRight'
          });
          setShowAdd(false);
          retrieveFilters();
        }
      } else {
        notification.error({
          message: 'Filter Error',
          description: 'There was a problem creating this filter. Please check it has not already been created otherwise contact support.',
          placement: 'bottomRight'
        })
      }
    } else if(type === 'update') {
      try {
        const filterId = item.id;
        await Api.put(`filters/${filterId}/update`, { name: value, name_translations: translationDictionary });

        if(files.length > 0) {
          files.map(async (filterItem) => {
            var img = URL.createObjectURL(filterItem);
            fetch(img)
            .then(async (res) => res.blob())
            .then(async (blob) => {
              var options = {
                method: 'POST',
                headers: {
                  "Api-Arg": JSON.stringify({
                    name: filterItem.name,
                    file_type: Utils.getFileExtension(filterItem.name)
                  }),
                  "Content-Type": filterItem.type,
                  "Authorization": `JWT ${TokenStorage.getToken()}`
                },
                data: blob,
                url: `${process.env.REACT_APP_API_URL}filters/${filterId}/upload_icon`
              }
    
              try {
                await axios(options);

                notification.success({
                  message: 'Filter Success',
                  description: 'The filter has been updated successfully!',
                  placement: 'bottomRight'
                });
                
                setShowAdd(false);
                retrieveFilters();

              } catch(error) {
                console.log(error);
              }
            });
          });
        } else {
          notification.success({
            message: 'Filter Success',
            description: 'The filter has been updated successfully!',
            placement: 'bottomRight'
          });
          
          setShowAdd(false);
          retrieveFilters();
        }
      } catch(error) {
        notification.error({
          message: 'Filter Error',
          description: 'There was a problem updating this filter. Please try again or contact support.',
          placement: 'bottomRight'
        })
      }
    }
  }

  return (
    <MainLayout pageTitle={'Filters'} pageIconTitle={'filters'} onClick={() => handleCreateNew()} {...props}>
      <Table dataSource={filters} columns={columns} showSorterTooltip={false} rowKey="id" pagination={{ pageSize: 100 }}>
      </Table>
      {
        showAdd ? <AddDialog 
          existingItem={selectedItem}
          showFileUploader={true}
          modalTitle={"Add new filter"} 
          nameLabel={"Name of filter"} 
          placeholderDescription={"Enter name"} 
          errorTitle={"Filter Error"} 
          errorDescription={"The filter name needs to be entered."}
          showNameTranslation={true}
          onAddClick={(type, value, item, files, translations) => handleAddOnClick(type, value, item, files, translations)} /> : null
      }
    </MainLayout>
  )
}

export default Filters