import React, { useEffect, useState } from 'react';
import { Col, notification, Row, Table, Switch } from 'antd';
import MainLayout from '../../common/MainLayout';
import Api from '../../../services/Api';

function Countries(props) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let cancel = false;

    const retrieveCountries = async () => {
      const { data } = await Api.get('countries/list');

      if(cancel) {
        return;
      }

      setCountries(data.countries);
    }
    
    retrieveCountries();

    return () => {
      cancel = true;
    }
  }, []);

  const columns = [
    {
      title: 'Active',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Switch onChange={() => countryOnChange(row.id, !row.active)} checked={row.active}  />
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
      defaultSortOrder: 'ascend',
      width: 350,
      // sortDirections: ['ascend', 'descend']
      //...this.getColumnSearchProps('name'),
    },
    {
      title: 'Continent',
      dataIndex: 'continent',
      key: 'continent',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.continent.toUpperCase() > b.continent.toUpperCase()) cmp = 1;
        else if(a.continent.toUpperCase() < b.continent.toUpperCase()) cmp = -1;

        return cmp;
      },
      //...this.getColumnSearchProps('continent'),
    },
  ];

  const countryOnChange = async (countryId, checked) => {
    try {
      const { data } = await Api.put(`countries/${countryId}/update`, { active: checked });
      updateCountries(countryId, checked);

      notification.success({
        message: 'Country Success',
        description: 'The country has been updated successfully!',
        placement: 'bottomRight'
      });
    } catch(error) {
      console.log(error)
      notification.error({
        message: 'Country Error',
        description: 'There was a problem updating this country. Please try again or contact support.',
        placement: 'bottomRight'
      })
    }
  }

  const updateCountries = (countryId, active) => {
    let filteredCurrentCountries = [...countries];

    for(var i = 0; i < filteredCurrentCountries.length; i++) {
      if(filteredCurrentCountries[i].id === countryId) {
        filteredCurrentCountries[i].active = active;
        break;
      }
    }

    setCountries(filteredCurrentCountries);
  }

  return (
    <MainLayout pageTitle={'Countries'} pageIconTitle={'countries'} hideCreateButton={true} {...props}>
      <Table dataSource={countries} columns={columns} showSorterTooltip={false} rowKey="name" pagination={{ pageSize: 100 }}>
      </Table>
    </MainLayout>
  )
}

export default Countries