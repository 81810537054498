import React from 'react';
import { Menu } from 'antd';
import {
  AreaChartOutlined,
  SettingOutlined,
  TeamOutlined,
  TagOutlined,
  UserOutlined
} from '@ant-design/icons';
import PageRoutes from '../../services/PageRoutes';

const { SubMenu } = Menu;

function MainMenu(props) {
  const navigateRoute = (link) => {
    switch(link) {
      case 'active_ingredients':
        props.history.push(PageRoutes.ActiveIngredients);
        break;
      case 'brands':
        props.history.push(PageRoutes.Brands);
        break;
      case 'categories':
        props.history.push(PageRoutes.Categories);
        break;
      case 'filters':
        props.history.push(PageRoutes.Filters);
        break;
      case 'countries':
        props.history.push(PageRoutes.Countries);
        break;
      case 'products':
        props.history.push(PageRoutes.Products);
        break;
      case 'users':
        props.history.push(PageRoutes.Users);
        break;
      case 'farmers':
        props.history.push(PageRoutes.Farmers);
        break;
      case 'reports':
        props.history.push(PageRoutes.Reports);
        break;
      default:
        break;
    }
  }

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[props.location.pathname]} defaultOpenKeys={['/portal/settings']}>
      <Menu.Item key="/portal/products" icon={<TagOutlined />} onClick={() => navigateRoute('products')}>
        Products
      </Menu.Item>
      <Menu.Item key="/portal/farmers" icon={<TeamOutlined />} onClick={() => navigateRoute('farmers')}>
        Farmers
      </Menu.Item>
      <Menu.Item key="/portal/users" icon={<UserOutlined />} onClick={() => navigateRoute('users')}>
        GEA Users
      </Menu.Item>
      <Menu.Item key="/portal/reports" icon={<AreaChartOutlined />} onClick={() => navigateRoute('reports')}>
        Reports
      </Menu.Item>
      <SubMenu key="/portal/settings" icon={<SettingOutlined />} title="Settings">
        <Menu.Item key="/portal/settings/active_ingredients" onClick={() => navigateRoute('active_ingredients')}>Active Ingredients</Menu.Item>
        <Menu.Item key="/portal/settings/brands" onClick={() => navigateRoute('brands')}>Brands</Menu.Item>
        <Menu.Item key="/portal/settings/categories" onClick={() => navigateRoute('categories')}>Categories</Menu.Item>
        <Menu.Item key="/portal/settings/countries" onClick={() => navigateRoute('countries')}>Countries</Menu.Item>
        <Menu.Item key="/portal/settings/filters" onClick={() => navigateRoute('filters')}>Filters</Menu.Item>
      </SubMenu>
    </Menu>
  )
}

export default MainMenu;