import React from 'react';
import { Spin, Row, Layout, Col } from 'antd';

const { Content } = Layout;

var styles = {
  loaderContent: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh'
  },
  loaderContentCenter: {
    textAlign: 'center'
  },
  loaderLoadingText: {
    marginTop: 20,
    fontWeight: 'bold',
  }
}

function ContentLoader() {
  return (
    <Layout>
      <Content style={styles.loaderContent}>
        <Row type="flex" justify="center" align="middle">
          <Col style={styles.loaderContentCenter}>
            <Spin size="large" />
            <p style={styles.loaderLoadingText}>LOADING PAGE</p>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ContentLoader