export default {
  CITY: 'CITY', 
  COUNTRY: 'COUNTRY',
  FARM_SIZE: 'FARM_SIZE',
  NUMBER_OF_COWS: 'NUMBER_OF_COWS',
  MILK_VOLUME: 'MILK_VOLUME',
  EMPLOYEES: 'EMPLOYEES',
  CHEMICAL_DELIVERY_FREQUENCY: 'CHEMICAL_DELIVERY_FREQUENCY',
  FARM_NAME: 'FARM_NAME',
  POSTAL_CODE: 'POSTAL_CODE',
  MANUFACTURER_EQUIPMENT: 'MANUFACTURER_EQUIPMENT',
  MILKING_PARLOR: 'MILKING_PARLOR',
  AGE_OF_PARLOR: 'AGE_OF_PARLOR',
  NUMBER_OF_CLUSTERS: 'NUMBER_OF_CLUSTERS',
}