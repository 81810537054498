import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Switch,
  message,
  notification,
  Row,
  Steps,
  Table, 
  Col,
  Typography,
  Drawer,
  Card, 
  Checkbox,
  Tabs,
  Upload,
  Skeleton, 
  Popconfirm,
  Tree, 
  Divider
} from 'antd';
import ReactQuill from 'react-quill';
import { SafetyCertificateOutlined, TrademarkOutlined, TagsOutlined, CompassOutlined, FilterOutlined, PlusCircleOutlined, DeleteOutlined, RedoOutlined, InboxOutlined, FileImageOutlined, CheckCircleOutlined, SaveOutlined, SearchOutlined, DownloadOutlined, WarningOutlined, BookOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router';

import MainLayout from '../../common/MainLayout';
import Api from '../../../services/Api';
import ProductDocumentDialog from './ProductDocumentDialog';
import ButtonActions from '../../common/ButtonActions';
import ProductCheckboxType from '../../common/ProductCheckboxType';
import Utils from '../../common/Utils';
import ProductFileType from '../../common/ProductFileType';
import TokenStorage from '../../../services/TokenStorage';
import ProductLabels from './ProductLabels';
import ProductSerialNumber from './ProductSerialNumber';
import AddTranslation from '../../forms/AddTranslation';
import ContentLoader from '../../common/ContentLoader';

const { Text } = Typography;
const { TabPane } = Tabs;
const { Dragger } = Upload;

const modules = {
  toolbar: [
    [{ 'header': [3, 4, false] }],
    ['bold', 'italic'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 
  'list', 'bullet',
]

const MAX_TEMP_ID = 1000;

function EditProduct(props) {
  const [tempDocId, setTempDocId] = useState(1); //TODO: Update this
  // step 1
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [useLabels, setUseLabels] = useState(false);
  const [nameTranslations, setNameTranslations] = useState([]);
  const [summaryTranslations, setSummaryTranslations] = useState([]);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [showNameTranslation, setShowNameTranslation] = useState(false);
  const [showSummaryTranslation, setShowSummaryTranslation] = useState(false);
  const [showDescriptionTranslation, setShowDescriptionTranslation] = useState(false);
  // step 2
  const [cancel, setCancel] = useState(false);
  const [activeIngredients, setActiveIngredients] = useState([]);
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedActiveIngredients, setSelectedActiveIngredients] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  // step 3
  const [showForm, setShowForm] = useState(false);
  const [types, setTypes] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentCountries, setDocumentCountries] = useState({});
  // step 4
  const [imageFileList, setImageFileList] = useState([]);
  const [existingImage, setExistingImage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  // step 5
  const [labels, setLabels] = useState([]);

  const [productLoaded, setProductLoaded] = useState(true);
  const [activeIngredientsLoaded,setActiveIngredientsLoaded] = useState(true);
  const [brandsLoaded, setBrandsLoaded] = useState(true);
  const [countriesLoaded, setCountriesLoaded] = useState(true);
  const [filtersLoaded, setFiltersLoaded] = useState(true);
  const [typesLoaded, setTypesLoaded] = useState(true);

  const { productId } = useParams();

  const imageFileProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: file => {
      setImageFileList([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`${file.name} is not a png or jpg file`);
      } else {
        setImageFileList([file]);
        setSelectedImage(file);
        setPreviewImage(URL.createObjectURL(file));
        return false;
      }
    },
    imageFileList
  }

  useEffect(() => {
    const retrieveProduct = async () => {

      let currentCategories = [];
      let currentSelectedCategories = [];

      try {
        const { data } = await Api.get(`/products/${productId}/attributes`);
      
        if(data) {
          if(data.product) {
            let serialNumberArray = [];
            let nameTranslationsArray = [];
            let summaryTranslationsArray = [];
            let descriptionTranslationsArray = [];

            data.product.serial_numbers && data.product.serial_numbers.forEach((item, index) => {
              serialNumberArray.push({
                id: index + 1,
                serial_number: item
              });
            });

            if(data.product.name_translations) {
              for(let key in data.product.name_translations) {
                nameTranslationsArray.push({
                  id: nameTranslationsArray.length + 1,
                  language: key,
                  translation: data.product.name_translations[key]
                });
              }
            }

            if(data.product.summary_translations) {
              for(let key in data.product.summary_translations) {
                summaryTranslationsArray.push({
                  id: summaryTranslationsArray.length + 1,
                  language: key,
                  translation: data.product.summary_translations[key]
                });
              }
            }

            if(data.product.description_translations) {
              for(let key in data.product.description_translations) {
                descriptionTranslationsArray.push({
                  id: descriptionTranslationsArray.length + 1,
                  language: key,
                  translation: data.product.description_translations[key]
                });
              }
            }

            setName(data.product.name);
            setSummary(data.product.summary);
            setDescription(data.product.description);
            setExistingImage(data.product.image_url);
            setUseLabels(data.product.use_labels);
            setSerialNumbers(serialNumberArray);
            setNameTranslations(nameTranslationsArray);
            setSummaryTranslations(summaryTranslationsArray);
            setDescriptionTranslations(descriptionTranslationsArray);
          }
  
          if(data.active_ingredients) {
            let currentActiveIngredients = [];
            data.active_ingredients.map(item => currentActiveIngredients.push(item.active_ingredient_id));
            setSelectedActiveIngredients(currentActiveIngredients);
          }
  
          if(data.brands) {
            let currentBrands = [];
            data.brands.map(item => currentBrands.push(item.brand_id));
            setSelectedBrands(currentBrands);
          }
  
          if(data.categories) {
            data.categories.map(item => currentSelectedCategories.push(item.category_id));
            setSelectedCategories(currentSelectedCategories);
          }
  
          if(data.countries) {
            let currentCountries = [];
            data.countries.map(item => currentCountries.push(item.country_id));
            setSelectedCountries(currentCountries);
          }
  
          if(data.filters) {
            let currentFilters = [];
            data.filters.map(item => currentFilters.push(item.filter_id));
            setSelectedFilters(currentFilters);
          }
  
          if(data.documents) {
            let currentDocuments = [];
            let currentImages = [];
  
            data.documents.map(item => {
              if(item.type_name === ProductFileType.SDS_DOCUMENT || item.type_name === ProductFileType.PRODUCT_DOCUMENT) {
                item.typeName = item.type_name;
                currentDocuments.push(item);
              }
            });
  
            setDocuments(currentDocuments);
          }

          if(data.labels) {
            setLabels(data.labels);
          }
        }
      } catch(error) {

      }

      try {
        const { data } = await Api.get('categories/list?all=true');
        currentCategories = data.categories;
      } catch(error) {

      }

      generateTree(currentCategories, currentSelectedCategories);

      setProductLoaded(false);

      if(cancel) {
        return;
      }
    }

    const retrieveActiveIngredients = async () => {
      const { data } = await Api.get('active_ingredients/list');
      setActiveIngredients(data.active_ingredients);

      setActiveIngredientsLoaded(false);
    }

    const retrieveBrands = async () => {
      const { data } = await Api.get('brands/list');
      setBrands(data.brands);

      setBrandsLoaded(false);
    }

    const retrieveCountries = async () => {
      const { data } = await Api.get('countries/list_active');
      setCountries(data.countries);

      setCountriesLoaded(false);
    }

    const retrieveFilters = async () => {
      const { data } = await Api.get('filters/list');
      setFilters(data.filters);

      setFiltersLoaded(false)
    }

    const retrieveTypes = async () => {
      const { data } = await Api.get('types/list');
      setTypes(data.types);

      setTypesLoaded(false);
    }

    retrieveProduct();
    // retrieveCategories();
    retrieveActiveIngredients();
    retrieveBrands();
    retrieveCountries();
    retrieveFilters();
    retrieveTypes();
  }, []);

  const steps = [
    {
      title: 'Details',
      description: 'The main details of the product',
    },
    {
      title: 'Attributes',
      description: 'The different attributes of the product',
    },
    {
      title: 'Documents',
      description: 'Attach documents related to the product'
    },
    {
      title: 'Image',
      description: 'Attach an image to the product'
    }
  ];

  const updateProductDetails = async () => {
    if(productId && productId > 0) {
      let hasErrors = true;

      if(name && description) {
        if(name.trim().length > 0 && description.trim().length > 0) {
          hasErrors = false;
        }
      }

      if(!hasErrors) {
        const nameTranslationDictionary = {};
        const summaryTranslationDictionary = {};
        const descriptionTranslationDictionary = {};
        const serialNumberArray = [];

        nameTranslations && nameTranslations.forEach(item => {
          nameTranslationDictionary[item.language] = item.translation;
        });

        summaryTranslations && summaryTranslations.forEach(item => {
          summaryTranslationDictionary[item.language] = item.translation;
        });

        descriptionTranslations && descriptionTranslations.forEach(item => {
          descriptionTranslationDictionary[item.language] = item.translation;
        });

        serialNumbers && serialNumbers.forEach(item => {
          serialNumberArray.push(item.serial_number);
        });

        try {
          const { data } = await Api.put(`products/${productId}/update`, 
            { 
              name: name,
              summary: summary,
              description: description,
              use_labels: useLabels,
              name_translations: nameTranslationDictionary,
              summary_translations: summaryTranslationDictionary,
              description_translations: descriptionTranslationDictionary,
              serial_numbers: serialNumberArray
            }, 
          );

          notification.success({
            message: 'Product Detail Update',
            description: 'The product details has been updated successfully!',
            placement: 'bottomRight'
          });
        } catch(error) {
          notification.error({
            message: 'Product Error',
            description: 'There was a problem updating the product details. Please make sure the name and description are filled in and try again or contact support.',
            placement: 'bottomRight'
          });
        }
      } else {
        notification.error({
          message: 'Product Error',
          description: 'Please make sure the name and description are not blank.',
          placement: 'bottomRight'
        });
      }
    }
  }

  const saveProductAttributes = async () => {
    let attributes = {};

    attributes['active_ingredients'] = [];
    attributes['brands'] = [];
    attributes['categories'] = [];
    attributes['filters'] = [];
    attributes['countries'] = [];

    selectedActiveIngredients.map(item => attributes['active_ingredients'].push({ active_ingredient_id: item }));
    selectedBrands.map(item => attributes['brands'].push({ brand_id: item }));
    selectedCategories.map(item => attributes['categories'].push({ category_id: item }));
    selectedFilters.map(item => attributes['filters'].push({ filter_id: item }));
    selectedCountries.map(item => attributes['countries'].push({ country_id: item }));

    try { 
      const { data } = await Api.put(`products/${productId}/update_attributes`, attributes);

      notification.success({
        message: 'Product Attributes Update',
        description: 'The product attributes has been updated successfully!',
        placement: 'bottomRight'
      });
    } catch(error) {
      notification.error({
        message: 'Product Error',
        description: 'There was a problem updating the product attributes. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const saveImage = () => {
    if(selectedImage) {
      var img = URL.createObjectURL(selectedImage);
        fetch(img)
        .then(async (res) => res.blob())
        .then(async (blob) => {
          var options = {
            method: 'POST',
            headers: {
              "Api-Arg": JSON.stringify({
                type_id: Utils.getDocumentTypeId(types, ProductFileType.PRODUCT_IMAGE),
                name: selectedImage.name,
                file_type: Utils.getFileExtension(selectedImage.name)
              }),
              "Content-Type": selectedImage.type,
              "Authorization": `JWT ${TokenStorage.getToken()}`
            },
            data: blob,
            url: `${process.env.REACT_APP_API_URL}products/${productId}/upload_image`
          }

          try {
            const { data } = await axios(options);
            if(data) {
              setExistingImage(data.image_url);
            }
          } catch(error) {

          }
        })
    }
  }

  const saveDocuments = async () => {
    //insert the product documents
    documents.map(async (item) => {
      if(item.id < MAX_TEMP_ID) {
        var file = item.fileList[0];
        var document = URL.createObjectURL(file);
  
        fetch(document)
        .then(async (res) => res.blob())
        .then(async (blob) => {
          var options = {
            method: 'POST',
            headers: {
              "Api-Arg": JSON.stringify({
                type_id: Utils.getDocumentTypeId(types, item.typeName),
                name: item.name,
                file_type: Utils.getFileExtension(file.name)
              }),
              "Content-Type": file.type,
              "Authorization": `JWT ${TokenStorage.getToken()}`
            },
            data: blob,
            url: `${process.env.REACT_APP_API_URL}products/${productId}/upload`
          }
  
          try {
            const { data } = await axios(options);

            if(data && data.id > 0) {
              let itemsToAdd = [];

              let currentDocumentCountries = documentCountries[item.id];

              if(currentDocumentCountries.length > 0) {
                currentDocumentCountries.map((documentItem, index) => {
                  itemsToAdd.push({
                    document_id: data.id,
                    country_id: documentItem
                  });
                });
              }

              await Api.post(`documents/${data.id}/countries/batch_upsert`, itemsToAdd);
            }
          } catch(error) {
            console.error(error);
          }
        });
      }
    });
  }

  const deleteDocumentRow = async (rowId) => {
    try {
      if(rowId > MAX_TEMP_ID) {
        await Api.delete(`documents/${rowId}/delete`);
      }

      const newDocuments = [...documents];
      newDocuments.splice(newDocuments.findIndex(item => item.id === rowId), 1)
      setDocuments(newDocuments)

      delete documentCountries[rowId];
    } catch(error) {
      notification.error({
        message: 'Products Error',
        description: 'There was a problem deleting this product. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const documentColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row key={'doc_' + index}>
          <Col>
            <Popconfirm placement="right" title={'Are you sure you want to delete this document?'} onConfirm={() => deleteDocumentRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10, marginRight: 5}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Button size="small" href={`${process.env.REACT_APP_API_URL}documents/download/${row.url}?access_token=${TokenStorage.getToken()}`} target="_blank" style={{marginRight: 5}}>
              <DownloadOutlined /> 
            </Button>
          </Col>
        </Row>
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, row, index) => {
        return <Row key={'doc_' + index}>
          <Col>
            <Text>{row.typeName.replace('_DOCUMENT', '')}</Text>
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Country',
      dataIndex: 'translated_countries',
      key: 'translated_countries',
    },
  ];

  const deleteLabelRow = async (rowId) => {
    // try {
    //   await Api.delete(`documents/${rowId}/delete`);

    //   const newDocuments = [...documents];
    //   newDocuments.splice(newDocuments.findIndex(item => item.id === rowId), 1)
    //   setDocuments(newDocuments)
    // } catch(error) {
    //   notification.error({
    //     message: 'Products Error',
    //     description: 'There was a problem deleting this product. Please try again or contact support.',
    //     placement: 'bottomRight'
    //   });
    // }
  }

  const labelColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row key={'doc_' + index}>
          <Col>
            <Popconfirm placement="right" title={'Are you sure you want to delete this label?'} onConfirm={() => deleteLabelRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10, marginRight: 5}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
  ];

  const showDrawer = () => {
    setShowForm(true);
  };

  const closeDrawer = () => {
    setShowForm(false);
  };

  const updateProductCheckbox = (type, data, checked) => {
    switch(type) {
      case ProductCheckboxType.FILTER:
        if(checked) {
          let filteredList = [...selectedFilters];
          if(filteredList.indexOf(data.id) === -1) {
            setSelectedFilters([...selectedFilters, data.id]);
          }
        } else {
          const newList = [...selectedFilters];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedFilters(newList)
        }
        break;
      case ProductCheckboxType.BRAND:
        if(checked) {
          let filteredList = [...selectedBrands];
          if(filteredList.indexOf(data.id) === -1) {
            setSelectedBrands([...selectedBrands, data.id]);
          }
        } else {
          const newList = [...selectedBrands];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedBrands(newList)
        }
        break;
      case ProductCheckboxType.ACTIVE_INGREDIENT:
        if(checked) {
          let filteredList = [...selectedActiveIngredients];
          if(filteredList.indexOf(data.id) === -1) {
            setSelectedActiveIngredients([...selectedActiveIngredients, data.id]);
          }
        } else {
          const newList = [...selectedActiveIngredients];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedActiveIngredients(newList)
        }
        break;
      case ProductCheckboxType.COUNTRY:
        if(checked) {
          let filteredList = [...selectedCountries];
          if(filteredList.indexOf(data.id) === -1) {
            setSelectedCountries([...selectedCountries, data.id]);
          }
        } else {
          const newList = [...selectedCountries];
          newList.splice(newList.findIndex(item => item.id === data.id), 1)
          setSelectedCountries(newList)
        }
        break;
      case ProductCheckboxType.CATEGORY:
        if(checked) {
          let filteredList = [...selectedCategories];
          if(filteredList.indexOf(data.id) === -1) {
            setSelectedCategories([...selectedCategories, data.id]);
          }
        } else {
          const newList = [...selectedCategories];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedCategories(newList)
        }
        break;
      default:
        break;
    }
  }

  const handleDocumentDialog = async (type, form, savedCountries) => {
    if(type === ButtonActions.CANCEL) {
      closeDrawer();
    } else if (type === ButtonActions.SUBMIT) {
      form.id = tempDocId;

      setDocumentCountries({ ...documentCountries, [tempDocId]: savedCountries });
      setDocuments([...documents, form]);
      setTempDocId(tempDocId + 1);
      closeDrawer();
    }
  }

  const onCategoryCheck = (selectedKeys, info) => {
    setSelectedCategories(selectedKeys);
  };

  const generateTree = (categories, selectedCategories) => {
    let categoryTree = [];

    if(categories) {
      let parentCategories = categories.filter(item => { return item.parent_id === null});

      for(var i = 0; i < parentCategories.length; i++) {
        let parentCategory = parentCategories[i];

        let children = [];
        let subCategoryList = categories.filter(item => { return item.parent_id === parentCategory.id });

        for(var j = 0; j < subCategoryList.length; j++) {
          let subCategory = subCategoryList[j];

          children.push({
            title: subCategory.name,
            key: subCategory.id
          })
        }

        categoryTree.push({
          title: parentCategory.name,
          key: parentCategory.id,
          children: children
        })
      }
    }

    let checkedKeys = [];
    selectedCategories.map(item => checkedKeys.push(item.id));

    setCategoryTree(categoryTree);
    setSelectedCategories(selectedCategories);
  }

  const handleProductSerialNumbers = (serialNumbers) => {
    setSerialNumbers(serialNumbers);
  }

  const handleNameTranslationsClick = () => {
    setShowNameTranslation(true)
  }

  const handleSummaryTranslationsClick = () => {
    setShowSummaryTranslation(true)
  }

  const handleDescriptionTranslationsClick = () => {
    setShowDescriptionTranslation(true)
  }

  const handleNameTranslations = (action, translations) => {
    if(action !== 'cancel') {
      setNameTranslations(translations)
    }

    setShowNameTranslation(false)
  }

  const handleSummaryTranslations = (action, translations) => {
    if(action !== 'cancel') {
      setSummaryTranslations(translations)
    }

    setShowSummaryTranslation(false)
  }

  const handleDescriptionTranslations = (action, translations) => {
    if(action !== 'cancel') {
      setDescriptionTranslations(translations)
    }

    setShowDescriptionTranslation(false)
  }

  return (
    <>
      {
        (productLoaded && activeIngredientsLoaded && brandsLoaded && filtersLoaded && countriesLoaded && typesLoaded) ? <ContentLoader /> : 
        <MainLayout pageTitle={'Edit Product'} pageIconTitle={'products'} hideCreateButton={true} hideBackButton={true} {...props}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Details" key="1" style={{overflow: 'auto'}}>
              <Row style={{padding: 20}} justify={"start"}>
                <Col span={24} style={{textAlign: 'left'}}>
                  <Text><Text strong>Product Details</Text> - the main details of the product</Text>
                  <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
                    <Form.Item label="Name">
                      <Input style={{width: 300}} value={name} onChange={(e) => setName(e.target.value)} />
                      <Button style={{ marginLeft: 10 }} type="primary" icon={<BookOutlined />} size={20} onClick={() => handleNameTranslationsClick()}>Name Translations ({nameTranslations.length})</Button>
                    </Form.Item>
                    <Form.Item label="Summary">
                      <Input  value={summary} onChange={(e) => setSummary(e.target.value)} />
                      <br/>
                      <Button style={{ marginTop: 10 }} type="primary" icon={<BookOutlined />} size={20} onClick={() => handleSummaryTranslationsClick()}>Summary Translations ({summaryTranslations.length})</Button>
                    </Form.Item>
                    <Form.Item label="Use Tags">
                      <div>
                        <Text>If "use tags" is switched on, then these tags will be shown in the product listings & search results.</Text>
                      </div>
                      <div style={{marginTop: 20}}>
                        <Switch onChange={(checked) => setUseLabels(checked)} checked={useLabels}  />
                      </div>
                    </Form.Item>
                    <Form.Item label="Description">
                      <ReactQuill 
                        theme="snow" 
                        modules={modules}
                        formats={formats}
                        value={description} 
                        onChange={(value) => setDescription(value)}
                        style={{
                          height: 350,
                          backgroundColor: '#fff'
                        }} />
                        <Button style={{ marginTop: 60 }} type="primary" icon={<BookOutlined />} size={20} onClick={() => handleDescriptionTranslationsClick()}>Description Translations ({descriptionTranslations.length})</Button>
                    </Form.Item>
                  </Form>
                  <Row style={{ marginTop: 10 }}>
                    <Text strong>Serial Numbers</Text>
                    <Col span={24} style={{ marginTop: 5 }}>
                      <ProductSerialNumber 
                        serialNumbersUpdated={handleProductSerialNumbers} 
                        existingNumbers={serialNumbers}
                      />
                    </Col>
                  </Row>
                  <Row style={{paddingTop: 30}}>
                    <Button type="primary" onClick={() => updateProductDetails()}>
                      <SaveOutlined /> Save Details
                    </Button>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Attributes" key="2">
              <Row style={{padding: 20}} justify={"start"}>
                <Col span={24} style={{textAlign: 'left'}}>
                  <Text><Text strong>Product Attributes</Text> - the different attributes of the product</Text><br/><br/>
                  <Text>Choose the product attribute below and mark the properties that are applicable to the product.</Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{marginTop: 20, marginLeft: 0}}>
                    <Tabs tabPosition={'left'} style={{marginLeft: 0}}>
                      <TabPane 
                        tab={
                          <span>
                            <SafetyCertificateOutlined />
                            Active Ingredients
                          </span>
                        } key="1">
                        <Card title={<span><SafetyCertificateOutlined /> <span style={{paddingLeft: 5}}>Active Ingredients</span></span>} style={{minHeight: 400}}>
                          <Text>Check all the active ingredients that apply to this product.</Text><br/><br/>
                          {activeIngredients.map(item => (
                            <div key={'ai_' + item.id}>
                              <Checkbox 
                                onChange={(chk) => updateProductCheckbox(ProductCheckboxType.ACTIVE_INGREDIENT, item, chk.target.checked)} 
                                defaultChecked={selectedActiveIngredients.indexOf(item.id) === -1 ? false : true}>
                                  {item.name}
                              </Checkbox>
                              <br/>
                            </div>
                          ))}
                        </Card>
                      </TabPane>
                      <TabPane 
                        tab={
                          <span>
                            <TrademarkOutlined />
                            Brands
                          </span>
                        }
                        key="2">
                        <Card title={<span><TrademarkOutlined /> <span style={{paddingLeft: 5}}>Brands</span></span>} style={{minHeight: 400}}>
                          <Text>Check all the brands that apply to this product.</Text><br/><br/>
                          {brands.map(item => (
                            <div key={'b_' + item.id}>
                              <Checkbox 
                                onChange={(chk) => updateProductCheckbox(ProductCheckboxType.BRAND, item, chk.target.checked)}
                                defaultChecked={selectedBrands.indexOf(item.id) === -1 ? false : true}>
                                {item.name}
                              </Checkbox>
                              <br/>
                            </div>
                          ))}
                        </Card>
                      </TabPane>
                      <TabPane
                        tab={
                          <span>
                            <TagsOutlined />
                            Categories
                          </span>
                        }
                        key="3">
                        <Card title={<span><TagsOutlined /> <span style={{paddingLeft: 5}}>Categories</span></span>} style={{minHeight: 400}}>
                          <Text>Check all the categories that apply to this product.</Text><br/><br/>
                          <div>
                            <Tree
                              checkable={true}
                              selectable={false}
                              defaultExpandAll={true}
                              onCheck={(selectedKeys, info) => onCategoryCheck(selectedKeys, info)}
                              checkedKeys={selectedCategories}
                              treeData={categoryTree}
                            />
                          </div>
                        </Card>
                      </TabPane>
                      <TabPane 
                        tab={
                          <span>
                            <CompassOutlined />
                            Countries
                          </span>
                        } key="4">
                        <Card title={<span><CompassOutlined /> <span style={{paddingLeft: 5}}>Countries</span></span>} style={{minHeight: 400}}>
                          <Text>Check all the countries that apply to this product. If a country is not available, enable it under <b>Settings &gt; Countries</b>.</Text><br/><br/>
                          {
                          countries.filter(function(country) {
                            return country.active;
                          }).map(item => (
                            <div key={'c_' + item.id}>
                              <Checkbox 
                                onChange={(chk) => updateProductCheckbox(ProductCheckboxType.COUNTRY, item, chk.target.checked)}
                                defaultChecked={selectedCountries.indexOf(item.id) === -1 ? false : true}>
                                {item.name}
                              </Checkbox>
                              <br/>
                            </div>
                          ))}
                        </Card>
                      </TabPane>
                      <TabPane 
                        tab={
                          <span>
                            <FilterOutlined />
                            Filters
                          </span>
                        } key="5">
                        <Card title={<span><FilterOutlined /> <span style={{paddingLeft: 5}}>Filters</span></span>} style={{minHeight: 400}}>
                          <Text>Check all the filters that apply to this product.</Text><br/><br/>
                          {filters.map(item => (
                            <div key={'f_' + item.id}>
                              <Checkbox 
                                onChange={(chk) => updateProductCheckbox(ProductCheckboxType.FILTER, item, chk.target.checked)}
                                defaultChecked={selectedFilters.indexOf(item.id) === -1 ? false : true}>
                                {item.name}
                              </Checkbox>
                              <br/>
                            </div>
                          ))}
                        </Card>
                      </TabPane>
                    </Tabs>
                  </div>
                </Col>
              </Row>
              <Row style={{paddingLeft: 20, paddingRight: 20}} justify={"start"}>
                <Divider />
                <Col>
                  <div style={{}}>
                    <Button type="primary" onClick={() => saveProductAttributes()}>
                      <SaveOutlined /> Save Attributes
                    </Button>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Documents" key="3">
              <Row style={{padding: 20}} justify={"start"}>
                <Col span={24} style={{textAlign: 'left'}}>
                  <Text><Text strong>Product Documents</Text> - attach documents related to the product</Text>
                  <Row style={{marginTop: 20}}>
                    <Col span={24}>
                      <Button type="primary" icon={<PlusCircleOutlined />} size={20} onClick={() => showDrawer()}>Add New Document</Button>
                    </Col>
                  </Row>
                  <Row style={{marginTop: 20}}>
                    <Col span={24}>
                      <Table dataSource={documents} columns={documentColumns} showSorterTooltip={false} rowKey="id">
                      </Table>
                    </Col>
                  </Row>
                  {
                    showForm ? <Drawer
                      title="Add a new document"
                      width={720}
                      onClose={() => closeDrawer()}
                      visible={showForm}
                      bodyStyle={{ paddingBottom: 80 }}>
                      <ProductDocumentDialog 
                        countries={countries} 
                        documentTypes={types} 
                        onClick={(type, form, selectedCountries) => handleDocumentDialog(type, form, selectedCountries)} />
                    </Drawer> : null
                  }
                  <div style={{marginTop: 20}}>
                    <Button type="primary" onClick={() => saveDocuments()}>
                      <SaveOutlined /> Save Documents
                    </Button>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Image" key="4">
              <Row gutter={[16, 16]} style={{padding: 20}} justify={"start"}>
                <Col span={12} style={{textAlign: 'left'}}>
                  <Text><Text strong>Product Image</Text> - attach an image to the product</Text>
                  <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
                    <Form.Item label="Current Product Image">
                      {
                        existingImage ? <img src={`${process.env.REACT_APP_API_URL}documents/download/${existingImage}?access_token=${TokenStorage.getToken()}&t=${new Date().getTime()}`} alt="" width={400} height={400} /> : <Skeleton.Image style={{width: 400, height: 400}} />
                      }
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={12} style={{textAlign: 'left'}}>
                  <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
                    <Form.Item label="Select image and upload">
                      <div style={{marginBottom: 10}}>
                        <Text>Image sizes are recommended to be square for optimal display, e.g 400x400, 512x512, 1280x1280, etc.</Text>
                      </div>
                      <Dragger {...imageFileProps} fileList={imageFileList} showUploadList={true}>
                        <p className="ant-upload-drag-icon">
                          <FileImageOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag an image to this area to upload</p>
                        <p className="ant-upload-hint">
                          We will only support png or jpg images.
                        </p>
                      </Dragger>
                    </Form.Item>
                    <Form.Item label="Upload image preview">
                      {
                        previewImage ? <img src={previewImage} alt="" style={{width: 200, height: 200}} /> : <Skeleton.Image style={{width: 200, height: 200}} />
                      }
                    </Form.Item>
                  </Form>
                  <Divider />
                  <div style={{marginTop: 30}}>
                    <Button type="primary" onClick={() => saveImage()}>
                      <SaveOutlined /> Save Image
                    </Button>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Tags" key="5">
              <ProductLabels productId={productId} existingLabels={labels} />
            </TabPane>
          </Tabs>
          {
            showNameTranslation ? <AddTranslation 
              modalTitle={"Add name translations"} 
              nameLabel={"Enter name translation *"} 
              existingTranslations={nameTranslations}
              onAddClick={(action, translations) => handleNameTranslations(action, translations)} /> : null
          }

          {
            showSummaryTranslation ? <AddTranslation 
              modalTitle={"Add summary translations"} 
              nameLabel={"Enter summary translation *"} 
              existingTranslations={summaryTranslations}
              onAddClick={(action, translations) => handleSummaryTranslations(action, translations)} /> : null
          }

          {
            showDescriptionTranslation ? <AddTranslation 
              modalTitle={"Add description translations"} 
              nameLabel={"Enter description translation *"} 
              existingTranslations={descriptionTranslations}
              useTextArea={true}
              onAddClick={(action, translations) => handleDescriptionTranslations(action, translations)} /> : null
          }
        </MainLayout>
      }
    </>
  )
}


export default EditProduct