import React, { useEffect, useState } from 'react';
import { Button, Modal, Row } from 'antd';
import TranslationNameTab from './TranslationNameTab';

function AddTranslation(props) {
  const [existingTranslations, setExistingTranslations] = useState(null);
  const [savedTranslations, setSavedTranslations] = useState([]);

  useEffect(() => {
    if(props.existingTranslations) {
      let translationDictionary = {};

      for(let i = 0; i < props.existingTranslations.length; i++) {
        let translation = props.existingTranslations[i];
        translationDictionary[translation.language] = translation.translation;
      }

      setExistingTranslations(translationDictionary);
    }
  }, [props.existingTranslations]);

  const addItem = () => {
    props.onAddClick('update', savedTranslations);
  }

  const handleCancel = () => {
    props.onAddClick('cancel', savedTranslations);
  }

  const handleTranslations = (translations) => {
    setSavedTranslations(translations);
  }

  return (
    <Modal
      visible={true}
      title={props.modalTitle}
      closable={false}
      width={props.useTextArea ? 800 : 600}
      footer={[
        <Button key="back" onClick={() => handleCancel()}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={() => addItem()}>
          Save
        </Button>,
      ]}
    >
      <Row justify={"start"}>
        <TranslationNameTab
          nameLabel={props.nameLabel}
          useTextArea={props.useTextArea}
          existingTranslations={existingTranslations}
          translationUpdated={(translations) => handleTranslations(translations)}
        />
      </Row>
    </Modal>
  )
}

export default AddTranslation