import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Form,
  Input,
  Radio,
  Select,
  InputNumber,
  Switch,
  message,
  Row,
  Steps,
  Col,
  Typography,
  Tabs,
  Upload,
  Skeleton,
  Divider,
  notification,
  Button,
  Popconfirm, 
} from 'antd';
import Icon, { CloseOutlined, FileImageOutlined, PlusOutlined } from '@ant-design/icons';

import MainLayout from '../../common/MainLayout';
import Api from '../../../services/Api';
import AttributeName from '../../common/AttributeName';
import Utils from '../../common/Utils';
import PageRoutes from '../../../services/PageRoutes';
import TokenStorage from '../../../services/TokenStorage';
import RoleType from '../../common/RoleType';

const { Text } = Typography;
const { Dragger } = Upload;

function CreateFarmer(props) {
  
  const [imageFileList, setImageFileList] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState(0);

  const [countries, setCountries] = useState([]);
  const [attributes, setAttributes] = useState([]);

  const [city, setCity] = useState('');
  const [farmSize, setFarmSize] = useState(0);
  const [numberOfCows, setNumberOfCows] = useState(0);
  const [milkVolume, setMilkVolume] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [farmName, setFarmName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [manufacturerEquipment, setManufacturerEquipment] = useState('');
  const [milkingParlor, setMilkingParlor] = useState('');
  const [ageOfParlor, setAgeOfParlor] = useState(0);
  const [numberOfClusters, setNumberOfClusters] = useState(0);

  const [roles, setRoles] = useState([]);

  const imageFileProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: file => {
      setImageFileList([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`${file.name} is not a png or jpg file`);
      } else {
        setImageFileList([file]);
        setPreviewImage(URL.createObjectURL(file));
        return false;
      }
    },
    imageFileList
  }

  useEffect(() => {
    let cancel = false;

    retrieveCountries();
    retrieveAttributes();
    retrieveRoles();

    return () => {
      cancel = true;
    }
  }, []);

  const retrieveCountries = async () => {
    try {
      const { data } = await Api.get('countries/list');
      setCountries(data.countries);
    } catch(error) {

    }
  }

  const retrieveAttributes = async () => {
    try {
      const { data } = await Api.get('attributes/list');
      setAttributes(data.attributes);
    } catch(error) {

    }
  }

  const retrieveRoles = async () => {
    try {
      const { data } = await Api.get('roles/list');
      setRoles(data.roles);
    } catch(error) {

    }
  }

  const getRoleId = (roleName) => {
    let rolesFound = roles.filter((item) => { return item.name === roleName; });

    if(rolesFound.length > 0) {
      return rolesFound[0].id;
    }

    return 0;
  }

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
  }

  const updateAttribute = (e, type) => {
    switch(type) {
      case AttributeName.CITY:
        setCity(e.target.value);
        break;
      case AttributeName.FARM_SIZE:
        setFarmSize(e);
        break;
      case AttributeName.NUMBER_OF_COWS:
        setNumberOfCows(e);
        break;
      case AttributeName.MILK_VOLUME:
        setMilkVolume(e);
        break;
      case AttributeName.EMPLOYEES:
        setEmployees(e);
        break;
      case AttributeName.FARM_NAME:
        setFarmName(e.target.value);
        break;
      case AttributeName.POSTAL_CODE:
        setPostalCode(e.target.value);
        break;
      case AttributeName.MANUFACTURER_EQUIPMENT:
        setManufacturerEquipment(e.target.value);
        break;
      case AttributeName.MILKING_PARLOR:
        setMilkingParlor(e.target.value);
        break;
      case AttributeName.AGE_OF_PARLOR:
        setAgeOfParlor(e);
        break;
      case AttributeName.NUMBER_OF_CLUSTERS:
        setNumberOfClusters(e);
        break;
    }
  }

  const createNewUser = async () => {
    let errors = validateForm();
    
    if(errors.length === 0) {
      let userProfile = {
        "user": {
          "first_name": firstName,
          "last_name": lastName,
          "email": email,
          "country_id": country,
          "password": "thisisapassword",
          "password_confirmation": "thisisapassword"
        }
      }

      try {
        const { data } = await Api.post('users/admin_create', userProfile);

        if(data && data.user) {
          if(data.user.id > 0) {
            let userAttributes = compileAttributes(parseInt(data.user.id));
    
            await Api.post('user_attributes/admin_batch_upsert', userAttributes);
            await uploadProfileImage(data.user.id);

            props.history.replace(PageRoutes.Farmers);
          }
        }
      } catch(error) {
        let errorMessage = 'There was a problem creating this farmer. Please ensure details are correct and try again or contact support.';
        if(error.response && error.response.data && error.response.data.error) {
          errorMessage = `There was a problem creating this farmer. ${error.response.data.error}.`;
        }

        notification.error({
          message: 'Farmer Error',
          description: errorMessage,
          placement: 'bottomRight'
        });
      }
    } else {
      let errorMessage = [];

      errors.map((item, index) => {
        errorMessage.push(<div key={index} style={{textAlign: 'left'}}>- {item}</div>)
      });

      notification.error({
        message: 'User Error',
        description: errorMessage,
        placement: 'bottomRight'
      });
    }
  }

  const uploadProfileImage = async (userId) => {
    imageFileList.map(async (item) => {
      var img = URL.createObjectURL(item);
      fetch(img)
      .then(async (res) => res.blob())
      .then(async (blob) => {
        var options = {
          method: 'POST',
          headers: {
            "Api-Arg": JSON.stringify({
              name: item.name,
              file_type: Utils.getFileExtension(item.name)
            }),
            "Content-Type": item.type,
            "Authorization": `JWT ${TokenStorage.getToken()}`
          },
          data: blob,
          url: `${process.env.REACT_APP_API_URL}users/${userId}/upload_profile_image`
        }

        try {
          const { data } = await axios(options);
        } catch(error) {
          console.log(error);
        }
      });
    });
  }

  const cancelCreateUser = () => {
    props.history.push(PageRoutes.Farmers);
  }

  const compileAttributes = (userId) => {
    let userAttributes = [];

    let cityAttribute = attributes.filter((item) => { return item.name ===  AttributeName.CITY; });
    let farmSizeAttribute = attributes.filter((item) => { return item.name ===  AttributeName.FARM_SIZE; });
    let numberOfCowsAttribute = attributes.filter((item) => { return item.name ===  AttributeName.NUMBER_OF_COWS; });
    let milkVolumeAttribute = attributes.filter((item) => { return item.name ===  AttributeName.MILK_VOLUME; });
    let employeesAttribute = attributes.filter((item) => { return item.name ===  AttributeName.EMPLOYEES; });
    let farmNameAttribute = attributes.filter((item) => { return item.name ===  AttributeName.FARM_NAME; });
    let postalCodeAttribute = attributes.filter((item) => { return item.name ===  AttributeName.POSTAL_CODE; });
    let manufacturerEquipmentAttribute = attributes.filter((item) => { return item.name ===  AttributeName.MANUFACTURER_EQUIPMENT; });
    let milkingParlorAttribute = attributes.filter((item) => { return item.name ===  AttributeName.MILKING_PARLOR; });
    let ageOfParlorAttribute = attributes.filter((item) => { return item.name ===  AttributeName.AGE_OF_PARLOR; });
    let numberOfClustersAttribute = attributes.filter((item) => { return item.name ===  AttributeName.NUMBER_OF_CLUSTERS; });

    userAttributes.push({ user_id: userId, attribute_id: cityAttribute ? (cityAttribute.length > 0 ? cityAttribute[0].id : 0) : 0, value: city.toString() });
    userAttributes.push({ user_id: userId, attribute_id: farmSizeAttribute ? (farmSizeAttribute.length > 0 ? farmSizeAttribute[0].id : 0) : 0, value: farmSize.toString() });
    userAttributes.push({ user_id: userId, attribute_id: numberOfCowsAttribute ? (numberOfCowsAttribute.length > 0 ? numberOfCowsAttribute[0].id : 0) : 0, value: numberOfCows.toString() });
    userAttributes.push({ user_id: userId, attribute_id: milkVolumeAttribute ? (milkVolumeAttribute.length > 0 ? milkVolumeAttribute[0].id : 0) : 0, value: milkVolume.toString() });
    userAttributes.push({ user_id: userId, attribute_id: employeesAttribute ? (employeesAttribute.length > 0 ? employeesAttribute[0].id : 0) : 0, value: employees.toString() });
    userAttributes.push({ user_id: userId, attribute_id: farmNameAttribute ? (farmNameAttribute.length > 0 ? farmNameAttribute[0].id : 0) : 0, value: farmName.toString() });
    userAttributes.push({ user_id: userId, attribute_id: postalCodeAttribute ? (postalCodeAttribute.length > 0 ? postalCodeAttribute[0].id : 0) : 0, value: postalCode.toString() });
    userAttributes.push({ user_id: userId, attribute_id: manufacturerEquipmentAttribute ? (manufacturerEquipmentAttribute.length > 0 ? manufacturerEquipmentAttribute[0].id : 0) : 0, value: manufacturerEquipment.toString() });
    userAttributes.push({ user_id: userId, attribute_id: milkingParlorAttribute ? (milkingParlorAttribute.length > 0 ? milkingParlorAttribute[0].id : 0) : 0, value: milkingParlor.toString() });
    userAttributes.push({ user_id: userId, attribute_id: ageOfParlorAttribute ? (ageOfParlorAttribute.length > 0 ? ageOfParlorAttribute[0].id : 0) : 0, value: ageOfParlor.toString() });
    userAttributes.push({ user_id: userId, attribute_id: numberOfClustersAttribute ? (numberOfClustersAttribute.length > 0 ? numberOfClustersAttribute[0].id : 0) : 0, value: numberOfClusters.toString() });

    return userAttributes;
  }

  const validateForm = () => {
    let errors = [];

    if(firstName && firstName.trim().length > 0) {
    } else {
      errors.push('The first name is required');
    }

    if(lastName && lastName.trim().length > 0) {
    } else {
      errors.push('The last name is required');
    }

    if(email && email.trim().length > 0) {
      if(!Utils.validateEmail(email)) {
        errors.push('The email address is invalid');
      }
    } else {
      errors.push('The email address is required');
    }

    if(country && country > 0) {
    } else {
      errors.push('The country is required');
    }

    return errors;
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <MainLayout pageTitle={'Create New Farmer'} pageIconTitle={'farmers'} hideCreateButton={true} hideBackButton={true} {...props}>
      <Row justify={"start"}>
        <Col span={12} style={{textAlign: 'left'}}>
          <Text><Text strong>Farmer Details</Text> - the main details for the user</Text>
          <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
            <Col span={12}>
              <Form.Item label="First name *">
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last name *">
                <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label="Email address *">
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
            </Col>
            <Form.Item label="Country *">
              <Radio.Group onChange={(e) => onChangeCountry(e)} value={country}>
                {
                  countries.filter(function(country) {
                    return country.active;
                  }).map(item => (
                    <Radio key={item.id} value={item.id} style={radioStyle}>{item.name}</Radio>
                  ))
                }
              </Radio.Group>
            </Form.Item>
            <Text strong>Profile Information</Text>
            <br/><br/>
            <Col span={12}>
              <Form.Item label="Farm Name">
                <Input onChange={(e) => updateAttribute(e, AttributeName.FARM_NAME)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="City">
                <Input onChange={(e) => updateAttribute(e, AttributeName.CITY)} />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Postal Code">
                <Input onChange={(e) => updateAttribute(e, AttributeName.POSTAL_CODE)} />
              </Form.Item>
            </Col>
            <Form.Item label="Milk Volume">
              <InputNumber onChange={(e) => updateAttribute(e, AttributeName.MILK_VOLUME)} />
            </Form.Item>
            <Form.Item label="Farm Size">
              <InputNumber onChange={(e) => updateAttribute(e, AttributeName.FARM_SIZE)} />
            </Form.Item>
            <Form.Item label="Number of Cows">
              <InputNumber onChange={(e) => updateAttribute(e, AttributeName.NUMBER_OF_COWS)} />
            </Form.Item>
            <Col span={12}>
              <Form.Item label="Milking Parlor">
                <Input onChange={(e) => updateAttribute(e, AttributeName.MILKING_PARLOR)} />
              </Form.Item>
            </Col>
            <Form.Item label="Age of Parlor">
              <InputNumber onChange={(e) => updateAttribute(e, AttributeName.AGE_OF_PARLOR)} />
            </Form.Item>
            <Form.Item label="Number of Clusters">
              <InputNumber onChange={(e) => updateAttribute(e, AttributeName.NUMBER_OF_CLUSTERS)} />
            </Form.Item>
            <Col span={12}>
              <Form.Item label="Manufacturer Equipment">
                <Input onChange={(e) => updateAttribute(e, AttributeName.MANUFACTURER_EQUIPMENT)} />
              </Form.Item>
            </Col>
          </Form>
        </Col>
        <Col span={12} style={{textAlign: 'left'}}>
          <Text><Text strong>Profile Image</Text> - attach a profile image to the user</Text>
          <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
            <Form.Item label="Select image and upload">
              <div style={{marginBottom: 10}}>
                <Text>Image sizes are recommended to be square for optimal display, e.g 400x400, 512x512, 1280x1280, etc.</Text>
              </div>
              <Dragger {...imageFileProps} fileList={imageFileList} showUploadList={true}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined />
                </p>
                <p className="ant-upload-text">Click or drag an image to this area to upload</p>
                <p className="ant-upload-hint">
                  We will only support png or jpg images.
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item label="Profile image preview">
              {
                previewImage ? <img src={previewImage} alt="" style={{width: 200, height: 200}} /> : <Skeleton.Image style={{width: 200, height: 200}} />
              }
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          
          <Popconfirm
            title="Are you sure want to cancel creating this user?"
            onConfirm={() => cancelCreateUser()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" style={{marginRight: 10}}><CloseOutlined />Cancel</Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure all the details are correct?"
            onConfirm={() => createNewUser()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary"><PlusOutlined />Create</Button>
          </Popconfirm>
        </Col>
      </Row>
    </MainLayout>
  )
}


export default CreateFarmer