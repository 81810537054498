import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Table,
  Button,
  Popconfirm,
  notification,

} from 'antd';
import MainLayout from '../../common/MainLayout';
import { CheckCircleOutlined, EditOutlined, DeleteOutlined, WarningOutlined, CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Api from '../../../services/Api';
import moment from 'moment';
import PageRoutes from '../../../services/PageRoutes';
import Utils from '../../common/Utils';

const { Title, Text } = Typography;

function ListUsers(props) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let cancel = false;

    const retrieveUsers = async () => {
      const { data } = await Api.post('users/admin_search', {});
      let filteredData = data.users.filter((item) => { return !item.deleted && (item.has_role || item.super_admin); })

      filteredData.sort((a, b) => {
        if(a.created_at > b.created_at) return -1;
        else if(a.created_at < b.created_at) return 1;
        else return 0;
      });
      
      setUsers(filteredData);
    }
    
    retrieveUsers();

    return () => {
      cancel = true;
    }
  }, []);

  const deleteRow = async (rowId) => {
    try {
      const { data } = await Api.delete(`users/${rowId}/admin_delete`);

      const newUsers = [...users];
      newUsers.splice(newUsers.findIndex(item => item.id === rowId), 1)
      setUsers(newUsers)

      if(data) {
        notification.success({
          message: 'User Deleted',
          description: 'The user has been deleted successfully!',
          placement: 'bottomRight'
        });
      }
    } catch(error) {
      notification.error({
        message: 'User Delete Error',
        description: 'There was a problem deleting this user. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const editRow = async (row) => {
    let fields = {
      ":userId": row.id
    }

    let path = PageRoutes.UserEdit;
    let final_path = Utils.getParameterization(path, fields);

    props.history.push(final_path);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Button type="default" size="small" onClick={() => editRow(row)}>
              <EditOutlined /> 
            </Button>
            <Popconfirm placement="right" title={'Are you sure you want to delete this user?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'First name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.first_name.toUpperCase() > b.first_name.toUpperCase()) cmp = 1;
        else if(a.first_name.toUpperCase() < b.first_name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Last name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.last_name.toUpperCase() > b.last_name.toUpperCase()) cmp = 1;
        else if(a.last_name.toUpperCase() < b.last_name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.email.toUpperCase() > b.email.toUpperCase()) cmp = 1;
        else if(a.email.toUpperCase() < b.email.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.country.toUpperCase() > b.country.toUpperCase()) cmp = 1;
        else if(a.country.toUpperCase() < b.country.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => {
        let cmp = 0;

        if(a.created_at > b.created_at) cmp = 1;
        else if(a.created_at < b.created_at) cmp = -1;

        return cmp;
      },
      render: (text, row, index) => {
        return <Text>{moment(row.created_at).format('L HH:mm')}</Text>
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (text, row, index) => {
        if(row.active) {
          return <CheckCircleOutlined style={{color: '#007f00'}} />
        } else {
          return <CloseCircleOutlined style={{color: '#f00'}} />
        }
      }
    },
  ];

  const createNew = () => {
    props.history.push(PageRoutes.UserCreate);
  }

  return (
    <MainLayout pageTitle={'Users'} pageIconTitle={'users'} hideCreateButton={true} hideBackButton={true} {...props}>
      <Button type="primary" icon={<PlusCircleOutlined />} size={20} onClick={() => createNew()}>Create New</Button>
      <Row style={{marginTop: 20}}>
        <Col span={24}>
          <Table dataSource={users} columns={columns} showSorterTooltip={false} rowKey="id" pagination={{ pageSize: 50 }}>
          </Table>
        </Col>
      </Row>
    </MainLayout>
  )
}

export default ListUsers