import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, notification, Upload, Typography, message, Col, Tabs, Row } from 'antd';
import { FileImageOutlined, TagOutlined } from '@ant-design/icons';
import TranslationNameTab from './TranslationNameTab';

const { Text } = Typography;
const { Dragger } = Upload;
const { TabPane } = Tabs;

function AddDialog(props) {
  const [name, setName] = useState('');
  const [item, setItem] = useState({});
  const [imageFileList, setImageFileList] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [showTab, setShowTab] = useState(false);

  useEffect(() => {
    let cancel = false;

    if(props.existingItem) {
      setItem(props.existingItem);
      setName(props.existingItem.name);
    }

    setShowTab(props.showNameTranslation);

    return () => {
      cancel = true;
    }
  }, [props.existingItem, props.showNameTranslation]);

  const addItem = () => {
    if(name && name.trim().length > 0) {
      if(props.existingItem && Object.keys(props.existingItem).length > 0) {
        props.onAddClick('update', name, props.existingItem, imageFileList, translations);
      } else {
        props.onAddClick('add', name, null, imageFileList, translations);
      }
    } else {
      notification.error({
        message: props.errorTitle,
        description: props.errorDescription,
        placement: 'bottomRight'
      })
    }
  }

  const handleCancel = () => {
    props.onAddClick('cancel', '', null);
  }

  const imageFileProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: file => {
      setImageFileList([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`${file.name} is not a png or jpg file`);
      } else {
        setImageFileList([file]);
        return false;
      }
    },
    imageFileList
  }

  const handleTranslations = (translations) => {
    setTranslations(translations);
  }

  return (
    <Modal
      visible={true}
      title={props.modalTitle}
      closable={false}
      width={600}
      footer={[
        <Button key="back" onClick={() => handleCancel()}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => addItem()}>
          Submit
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Details" key="1" style={{overflow: 'auto'}}>
          <Row style={{padding: 20}} justify={"start"}>
            <Col span={24}>
              <Form
                name="item_form"
                className=""
                style={{minHeight: 250}}
                layout="vertical"
                initialValues={{"item_name": name}} 
              >
                <Form.Item label={props.nameLabel}>
                  <Input 
                  name="item_name" 
                  prefix={<TagOutlined style={{paddingRight: 10}} />} 
                  value={name} 
                  onChange={(e) => setName(e.target.value)} 
                  placeholder={props.placeholderDescription} 
                  onPressEnter={() => addItem()}
                  ref={(input) => input && input.focus()} />
                </Form.Item>
                {
                  props.showFileUploader ?
                  <Form.Item label="Select image and upload">
                    <div style={{marginBottom: 10}}>
                      <Text>Image sizes are recommended to be square for optimal display, e.g 400x400, 512x512, 1280x1280, etc.</Text>
                    </div>
                    <Dragger {...imageFileProps} fileList={imageFileList} showUploadList={true}>
                      <p className="ant-upload-drag-icon">
                        <FileImageOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag an image to this area to upload</p>
                      <p className="ant-upload-hint">
                        We will only support png or jpg images.
                      </p>
                    </Dragger>
                  </Form.Item> : null
                }
              </Form>
            </Col>
          </Row>
        </TabPane>
        {
          showTab ? <TabPane tab="Translations" key="2" style={{overflow: 'auto'}}>
            <Row style={{padding: 20}} justify={"start"}>
              <TranslationNameTab
                existingTranslations={props.existingItem ? props.existingItem.name_translations : null}
                translationUpdated={(translations) => handleTranslations(translations)}
              />
            </Row>
          </TabPane> : null
        }
        
      </Tabs>
    </Modal>
  )
}

export default AddDialog