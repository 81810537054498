import React, { useState } from 'react';
import { Avatar, Button, Col, Layout, Menu, Row, Typography } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined, 
  PlusCircleOutlined, ArrowLeftOutlined, TagsOutlined, TagOutlined, FilterOutlined, SafetyCertificateOutlined, GlobalOutlined, TrademarkCircleOutlined, EnvironmentOutlined, CompassOutlined, SnippetsOutlined, AreaChartOutlined, TeamOutlined
} from '@ant-design/icons';

import MainMenu from '../common/MainMenu';
import Title from 'antd/lib/typography/Title';
import Utils from './Utils';

const { Header, Sider, Content } = Layout;
const { Text, Link } = Typography;

function MainLayout(props) {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const createNew = () => {
    props.onClick('CREATE_NEW');
  }

  const handleBack = () => {
    props.onClick('GO_BACK');
  }

  let pageIconTitle = null;

  if(props.pageIconTitle === 'brands') {
    pageIconTitle = <TrademarkCircleOutlined />;
  } else if(props.pageIconTitle === 'categories') {
    pageIconTitle = <TagsOutlined />;
  } else if(props.pageIconTitle === 'filters') {
    pageIconTitle = <FilterOutlined />;
  } else if(props.pageIconTitle === 'select_category') {
    pageIconTitle = <TagOutlined />;
  } else if(props.pageIconTitle === 'sub_categories') {
    pageIconTitle = <TagOutlined />;
  } else if(props.pageIconTitle === 'active_ingredients') {
    pageIconTitle = <SafetyCertificateOutlined />;
  } else if(props.pageIconTitle === 'countries') {
    pageIconTitle = <CompassOutlined />;
  } else if(props.pageIconTitle === 'products') {
    pageIconTitle = <SnippetsOutlined />;
  } else if(props.pageIconTitle === 'users') {
    pageIconTitle = <UserOutlined />;
  } else if(props.pageIconTitle === 'reports') {
    pageIconTitle = <AreaChartOutlined />;
  } else if(props.pageIconTitle === 'farmers') {
    pageIconTitle = <TeamOutlined />;
  } 

  return (
    <Layout id="components-layout-demo-custom-trigger" style={{overflow: 'auto'}}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <MainMenu {...props} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row>
            <Col span={18} push={6}>
              <Row justify="end" align="middle">
                <Avatar icon={<UserOutlined />} />
                <Text style={{paddingLeft: 10, paddingRight: 10}}>{Utils.getName(props)} | <Link style={{fontWeight: 'bold'}} onClick={() => Utils.logOut(props)}>Log Out</Link></Text>
              </Row>
            </Col>
            <Col span={6} pull={18}>{collapsed ? <MenuUnfoldOutlined className="trigger" onClick={() => toggle()} /> : <MenuFoldOutlined className="trigger" onClick={() => toggle()} />}</Col>
          </Row>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            overflow: 'auto'
          }}
        >
          <Title level={4}><span style={{paddingRight: 5}}>{pageIconTitle}</span> {props.pageTitle}</Title>
          {props.hideBackButton ? null : <Button type="default" style={{marginRight: 10}} icon={<ArrowLeftOutlined />} size={20} onClick={() => handleBack()}></Button>}
          {props.hideCreateButton ? null : <Button type="primary" icon={<PlusCircleOutlined />} size={20} onClick={() => createNew()}>Create New</Button>}
          <Row style={{marginTop: 20}}>
            <Col span={24}>
              {props.children}
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;