import React, { useState } from 'react';
import { Avatar, Col, Layout, Menu, Row, Typography } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import MainMenu from '../common/MainMenu';
import Utils from '../common/Utils';

const { Header, Sider, Content } = Layout;
const { Text, Link } = Typography;

function Landing(props) {
  const { history } = props;
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout id="components-layout-demo-custom-trigger">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <MainMenu {...props} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row>
            <Col span={18} push={6}>
              <Row justify="end" align="middle">
                <Avatar icon={<UserOutlined />} />
                <Text style={{paddingLeft: 10, paddingRight: 10}}>{Utils.getName(props)} | <Link style={{fontWeight: 'bold'}} onClick={() => Utils.logOut(props)}>Log Out</Link></Text>
              </Row>
            </Col>
            <Col span={6} pull={18}>{collapsed ? <MenuUnfoldOutlined className="trigger" onClick={() => toggle()} /> : <MenuFoldOutlined className="trigger" onClick={() => toggle()} />}</Col>
          </Row>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          Content
        </Content>
      </Layout>
    </Layout>
  );
}

export default Landing;