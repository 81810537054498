import React, { useEffect, useState } from 'react';

import { BookOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Popconfirm, Row, Select, Table } from "antd";
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import uuid from 'react-uuid';

const modules = {
  toolbar: [
    [{ 'header': [3, 4, false] }],
    ['bold', 'italic'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 
  'list', 'bullet',
]

function TranslationNameTab(props) {
  const [language, setLanguage] = useState(null);
  const [textTranslation, setTextTranslation] = useState('');
  const [translations, setTranslations] = useState([]);
  const [error, setError] = useState('');

  const supportedLanguages = [
    { value: 'DE', label: 'German (DE)' },
    { value: 'FR', label: 'French (FR)' },
    { value: 'ES', label: 'Spanish (ES)' },
    { value: 'IT', label: 'Italian (IT)' },
    { value: 'NL', label: 'Dutch (NL)' },
    { value: 'PL', label: 'Polish (PL)' },
  ]

  useEffect(() => {
    if(props.existingTranslations) {
      let tempTranslations = [];

      for (var key in props.existingTranslations) {
        const tempId = uuid();

        tempTranslations.push({
          id: tempId,
          language: key,
          translation: props.existingTranslations[key]
        });
      }

      setTranslations(tempTranslations);
    }
  }, [props.existingTranslations])

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  }

  const addItem = () => {
    setError('');

    let tempTranslations  = [...translations];
    let translationText = textTranslation.trim();

    if((language == null || language.length === 0) || translationText.length === 0) {
      setError('Both the language and translation are required.');
    } else {
      let existingLanguage = tempTranslations && tempTranslations.filter((item) => { return item.language === language });

      if(existingLanguage.length === 0) {
        const tempId = uuid();

        tempTranslations.push({
          id: tempId,
          language,
          translation: textTranslation
        });

        setLanguage(null);
        setTextTranslation('');
      } else {
        setError('The language already exists.')
      }
    }

    setTranslations(tempTranslations);
    props.translationUpdated(tempTranslations);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Popconfirm placement="right" title={'Are you sure you want to delete this language translation?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      width: 100,
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.language.toUpperCase() > b.language.toUpperCase()) cmp = 1;
        else if(a.language.toUpperCase() < b.language.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      key: 'translation',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.translation.toUpperCase() > b.translation.toUpperCase()) cmp = 1;
        else if(a.translation.toUpperCase() < b.translation.toUpperCase()) cmp = -1;

        return cmp;
      },
      render: (text) => {
        if (props.useTextArea) {
          const cleanHTML = DOMPurify.sanitize(text);
          return <div dangerouslySetInnerHTML={{__html: cleanHTML}}></div>
        } else {
          return <div>{text}</div>
        }
      }
    },
  ];

  const deleteRow = (id) => {
    let tempTranslations = [...translations];
    let filteredTranslations = tempTranslations.filter((item) => { return item.id !== id });

    setTranslations(filteredTranslations);

    props.translationUpdated(filteredTranslations);
  }

  return (
    <Col span={24}>
      <Form
        name="item_form"
        className=""
        style={{minHeight: 250}}
        layout="vertical"
        onFinish={(e) => e.preventDefault()}
      >
        <Form.Item label={"Language *"}>
            <Select
              value={language}
              style={{ width: '50%' }}
              onChange={handleLanguageChange}
              options={supportedLanguages}
            />
        </Form.Item>
        <Form.Item label={props.nameLabel}>
          {
            props.useTextArea ? <ReactQuill 
              theme="snow" 
              modules={modules}
              formats={formats}
              value={textTranslation} 
              onChange={(content) => setTextTranslation(content)}
              style={{
                height: 300,
                backgroundColor: '#fff'
              }} /> : <Input 
              name="translation" 
              prefix={<BookOutlined style={{paddingRight: 10}} />} 
              value={textTranslation} 
              onChange={(e) => setTextTranslation(e.target.value)} 
              onPressEnter={() => addItem()} />
          }
          
        </Form.Item>
        {
          props.useTextArea ? <Form.Item style={{ paddingTop: 30 }}>
            <Button key="add" onClick={() => addItem()}>
              Add Translation
            </Button>
          </Form.Item> : <Form.Item>
            <Button key="add" onClick={() => addItem()}>
              Add Translation
            </Button>
          </Form.Item>
        }
        {
          error && error.length > 0 ? <Row style={{paddingTop: 6, color: '#F00'}}>
            <Col span={24}>
              {error}
            </Col>
          </Row> : null
        }
      </Form>
      <Table dataSource={translations} columns={columns} showSorterTooltip={false} rowKey="id">
      </Table>
    </Col>
  )
}

export default TranslationNameTab