import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Form,
  Input,
  Radio,
  Switch,
  message,
  Row,
  Col,
  Typography,
  Upload,
  Skeleton,
  Divider,
  notification,
  Button,
  Popconfirm, 
} from 'antd';
import Icon, { CloseOutlined, FileImageOutlined, SaveOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';

import MainLayout from '../../common/MainLayout';
import Api from '../../../services/Api';
import Utils from '../../common/Utils';
import PageRoutes from '../../../services/PageRoutes';
import TokenStorage from '../../../services/TokenStorage';
import RoleType from '../../common/RoleType';

const { Text } = Typography;
const { Dragger } = Upload;

function EditUser(props) {
  
  const [imageFileList, setImageFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState(0);
  const [superAdministrator, setSuperAdministrator] = useState(false);
  const [currentProfileImage, setCurrentProfileImage] = useState('');

  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);

  const { userId } = useParams();

  const imageFileProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: file => {
      setImageFileList([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`${file.name} is not a png or jpg file`);
      } else {
        setImageFileList([file]);
        setPreviewImage(URL.createObjectURL(file));
        return false;
      }
    },
    imageFileList
  }

  useEffect(() => {
    let cancel = false;

    retrieveUser();
    retrieveCountries();
    retrieveRoles();

    return () => {
      cancel = true;
    }
  }, []);

  const retrieveUser = async () => {
    try {
      const { data } = await Api.get(`users/${userId}`);
      
      if(data && data.user) {
        setFirstName(data.user.first_name);
        setLastName(data.user.last_name);
        setEmail(data.user.email);
        setCountry(data.user.country_id);
        setCurrentProfileImage(data.user.profile_image_url);
        setSuperAdministrator(data.user.super_admin);
      }
    } catch(error) {

    }
  }

  const retrieveRoles = async () => {
    try {
      const { data } = await Api.get('roles/list');
      setRoles(data.roles);
    } catch(error) {

    }
  }

  const retrieveCountries = async () => {
    try {
      const { data } = await Api.get('countries/list');
      setCountries(data.countries);
    } catch(error) {

    }
  }

  const onChangeCountry = (e) => {
    setCountry(e.target.value);
  }

  const getRoleId = (roleName) => {
    let rolesFound = roles.filter((item) => { return item.name === roleName; });

    if(rolesFound.length > 0) {
      return rolesFound[0].id;
    }

    return 0;
  }

  const updateUserDetails = async () => {
    let errors = validateForm();
    
    if(errors.length === 0) {
      let userProfile = {
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "country_id": country,
        "super_admin": superAdministrator
      }

      try {
        await Api.put(`users/${userId}/admin_update`, userProfile);
        await uploadProfileImage(userId);

        props.history.replace(PageRoutes.Users);

      } catch(error) {
        console.log(error);

        notification.error({
          message: 'User Error',
          description: 'There was a problem creating this user. Please ensure details are correct and try again or contact support.',
          placement: 'bottomRight'
        });
      }
    } else {
      let errorMessage = [];

      errors.map((item, index) => {
        errorMessage.push(<div key={index} style={{textAlign: 'left'}}>- {item}</div>)
      });

      notification.error({
        message: 'User Error',
        description: errorMessage,
        placement: 'bottomRight'
      });
    }
  }

  const uploadProfileImage = async (userId) => {
    imageFileList.map(async (item) => {
      var img = URL.createObjectURL(item);
      fetch(img)
      .then(async (res) => res.blob())
      .then(async (blob) => {
        var options = {
          method: 'POST',
          headers: {
            "Api-Arg": JSON.stringify({
              name: item.name,
              file_type: Utils.getFileExtension(item.name)
            }),
            "Content-Type": item.type,
            "Authorization": `JWT ${TokenStorage.getToken()}`
          },
          data: blob,
          url: `${process.env.REACT_APP_API_URL}users/${userId}/upload_profile_image`
        }

        try {
          const { data } = await axios(options);
        } catch(error) {
          console.log(error);
        }
      });
    });
  }

  const cancelUpdateUser = () => {
    props.history.push(PageRoutes.Users);
  }

  const validateForm = () => {
    let errors = [];

    if(firstName && firstName.trim().length > 0) {
    } else {
      errors.push('The first name is required');
    }

    if(lastName && lastName.trim().length > 0) {
    } else {
      errors.push('The last name is required');
    }

    if(email && email.trim().length > 0) {
      if(!Utils.validateEmail(email)) {
        errors.push('The email address is invalid');
      }
    } else {
      errors.push('The email address is required');
    }

    if(country && country > 0) {
    } else {
      errors.push('The country is required');
    }

    return errors;
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <MainLayout pageTitle={'Edit Existing User'} pageIconTitle={'users'} hideCreateButton={true} hideBackButton={true} {...props}>
      <Row justify={"start"}>
        <Col span={12} style={{textAlign: 'left'}}>
          <Text><Text strong>User Details</Text> - the main details for the user</Text>
          <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
            <Col span={12}>
              <Form.Item label="First name *">
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last name *">
                <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label="Email address *">
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
            </Col>
            <Form.Item label="Country *">
              <Radio.Group onChange={(e) => onChangeCountry(e)} value={country}>
                {
                  countries.filter(function(country) {
                    return country.active;
                  }).map(item => (
                    <Radio key={item.id} value={item.id} style={radioStyle}>{item.name}</Radio>
                  ))
                }
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Does this user have access to the admin portal?">
              <Switch onChange={(checked) => setSuperAdministrator(checked)} checked={superAdministrator}  />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12} style={{textAlign: 'left'}}>
          <Text><Text strong>Profile Image</Text> - attach a profile image to the user</Text>
          
          <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
            <Form.Item label="Current profile image">
              {
                currentProfileImage ? <img src={`${process.env.REACT_APP_API_URL}documents/download/${currentProfileImage}?access_token=${TokenStorage.getToken()}`} alt="" style={{width: 200, height: 200}} /> : <Skeleton.Image style={{width: 200, height: 200}} />
              }
            </Form.Item>
            <Form.Item label="Select image and upload">
              <div style={{marginBottom: 10}}>
                <Text>Image sizes are recommended to be square for optimal display, e.g 400x400, 512x512, 1280x1280, etc.</Text>
              </div>
              <Dragger {...imageFileProps} fileList={imageFileList} showUploadList={true}>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined />
                </p>
                <p className="ant-upload-text">Click or drag an image to this area to upload</p>
                <p className="ant-upload-hint">
                  We will only support png or jpg images.
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item label="Profile image preview">
              {
                previewImage ? <img src={previewImage} alt="" style={{width: 200, height: 200}} /> : <Skeleton.Image style={{width: 200, height: 200}} />
              }
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          
          <Popconfirm
            title="Are you sure want to cancel the update of this user?"
            onConfirm={() => cancelUpdateUser()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="default" style={{marginRight: 10}}><CloseOutlined />Cancel</Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure all the details are correct?"
            onConfirm={() => updateUserDetails()}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary"><SaveOutlined />Save</Button>
          </Popconfirm>
        </Col>
      </Row>
    </MainLayout>
  )
}


export default EditUser