import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Typography
} from 'antd';
import MainLayout from '../../common/MainLayout';

const { Title, Text } = Typography;

function ReportsDashboard(props) {
  return (
    <MainLayout pageTitle={'Reports'} pageIconTitle={'reports'} hideCreateButton={true} hideBackButton={true} {...props}>
      <Row style={{padding: 20}} justify={"start"}>
        <Col span={24} style={{textAlign: 'left'}}>
          <Text><Text strong>Reports</Text> - there will be some reports shown here.</Text>
        </Col>
      </Row>
    </MainLayout>
  )
}

export default ReportsDashboard