import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  message,
  Typography,
  Upload, Button, Radio, Divider, notification, List, Checkbox
} from 'antd';
import { FileAddOutlined, InboxOutlined } from '@ant-design/icons';
import ButtonActions from '../../common/ButtonActions';
import ProductFileType from '../../common/ProductFileType';

const { Text } = Typography;
const { Dragger } = Upload;

function ProductDocumentDialog(props) {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [fileList, setFileList] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const fileProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: file => {
      setFileList([]);
    },
    beforeUpload: file => {
      if (file.type !== 'application/pdf') {
        message.error(`${file.name} is not a pdf file`);
      } else {
        setFileList([file]);
        return false;
      }
    },
    fileList
  }

  const getDocumentTypeId = (name) => {
    if(props.documentTypes) {
      let docType = props.documentTypes.filter((item) => item.name === name);
      if(docType.length == 1) {
        return docType[0].id;
      }
    }
   
    return -1;
  }

  const getDocumentTypeName = (id) => {
    if(props.documentTypes) {
      let docType = props.documentTypes.filter((item) => item.id === id);
      if(docType.length == 1) {
        return docType[0].name;
      }
    }
   
    return '';
  }

  const handleAdd = () => {
    if(name && type) {
      if(name.trim().length > 0 && type > 0 && (fileList !== null && fileList.length > 0)) {
        if(isValidFilename(name)) {
          props.onClick(ButtonActions.SUBMIT, { name, type, fileList, typeName: getDocumentTypeName(type)}, selectedCountries);
        } else {
          notification.error({
            message: 'Document Error',
            description: 'The name of the document only accepts alpha numeric (including _ or -) characters.',
            placement: 'bottomRight'
          });
        }
      } else {
        notification.error({
          message: 'Document Error',
          description: 'Please make sure the name, type and file are entered and selected.',
          placement: 'bottomRight'
        });
      }
    } else {
      notification.error({
        message: 'Document Error',
        description: 'Please make sure the name, type and file are entered and selected.',
        placement: 'bottomRight'
      });
    }
  }

  const handleSelectCountries = (checkedValues) => {
    setSelectedCountries(checkedValues);
  }

  const getCountriesChecklist = () => {
    return props.countries
      .filter(item => { return item.active })
      .map(country => { return { 
        label: country.name,
        value: country.id
      } 
    });
  }

  const isValidFilename = (string) => {
    let regex = new RegExp('^[A-Za-z0-9_-]+$');
    return string.match(regex);
  }

  return (
    <Row style={{padding: 20}} justify={"start"}>
      <Col span={24} style={{textAlign: 'left'}}>
        <Row>
          <Col span={24}>
            <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
              <Form.Item label="Name of document (alpha numeric and &quot;-_&quot; characters only, no spaces.)">
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </Form.Item>
              <Form.Item label="Select type">
                <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
                  <Radio value={getDocumentTypeId(ProductFileType.PRODUCT_DOCUMENT)}>Product Data Sheet</Radio>
                  <Radio value={getDocumentTypeId(ProductFileType.SDS_DOCUMENT)}>Safety Data Sheet</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Select document and upload">
                <Dragger {...fileProps} fileList={fileList} showUploadList={true}>
                  <p className="ant-upload-drag-icon">
                    <FileAddOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    We will only support PDF documents so they will be viewable on the mobile device.
                  </p>
                </Dragger>
              </Form.Item>
              {
                props.countries ?
                <Form.Item label="Select countries">
                  <Checkbox.Group options={getCountriesChecklist()} onChange={(e) => handleSelectCountries(e)} />
                </Form.Item> : null
              }
              <Divider />
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button onClick={() => props.onClick(ButtonActions.CANCEL, null, [])} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button onClick={() => handleAdd()} type="primary">
                  Add
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        
      </Col>
    </Row>
  )
}

export default ProductDocumentDialog