import axios from 'axios';
import { Router } from 'react-router-dom';
import TokenStorage from './TokenStorage';

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

Api.interceptors.request.use(
  config => {
    const token = TokenStorage.getToken();

    if(token) {
      config.headers['Authorization'] = `JWT ${token}`;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

Api.interceptors.response.use( (response) => {
  return response;
}, (error) => {
  
  const originalRequest = error.config;

  if (error.response.status === 401) {
    return TokenStorage.getNewToken()
      .then((token) => {
        const config = error.config;
        this.addJwtToken(token);

        return new Promise((resolve, reject) => {
          axios.request()
          .then(response => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
        });
      })
      .catch((error) => {
        Promise.reject(error);
      })
  }

  return new Promise((resolve, reject) => {
    reject(error);
  });
});

export const addJwtToken = (token) => {
  Api.defaults.headers.Authorization = `JWT ${token}`;
}

export const removeJwtToken = () => {
  delete Api.defaults.headers.Authorization;
}

export default Api;