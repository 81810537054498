import React, { useState } from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card, 
  Typography,
  notification,
} from 'antd';
import Api from '../../services/Api';
import PageRoutes from '../../services/PageRoutes';
import TokenStorage from '../../services/TokenStorage';

const { Text } = Typography;

function Login(props) {
  const { history } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const performLogin = async () => {
    if(email && password) {
      try {
        const { data } = await Api.post('users/admin_sign_in', { email, password });

        if(data && data.token) {
          if(data.user.super_admin) {
            TokenStorage.setTokens(data);
            history.push(PageRoutes.Products);
          } else {
            showNotification('You are not authorized to use this portal.')
          }
        } else {
          showNotification('Please check that you have entered in the correct details.');
        }
      } catch(error) {
        let errorMessage = 'There was a problem with site login, you may not be authorized. Please contact support.';

        if(error.response) {
          if(error.response.status === 404) {
            errorMessage = 'There was a problem logging you in. Please check that you have entered in the correct details.';
          }
        }

        showNotification(errorMessage);
      }
      
    } else {
      showNotification('The email & password are required.');
    }
  };

  const showNotification = (message) => {
    notification.error({
      message: 'LOGIN ERROR',
      description: message,
      placement: 'bottomRight'
    })
  }

  const handleForgotPassword = () => {
    props.history.push(PageRoutes.ForgotPassword);
  }

  return (
    <div style={{backgroundColor: '#eee'}}>
      <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
        <Col span={4}>
          <Card
            style={{ width: 350, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "5px 8px 24px 5px rgba(0, 0, 0, 0.1)" }}
            actions={[
              <Text onClick={() => handleForgotPassword()}><UnlockOutlined key="forgot_password" /> Forgot Password</Text>,
            ]}
          >
            <Row type="flex" justify="center" style={{paddingTop: 20}}>
              <Col>
                <img
                  width="200"
                  alt="GEA"
                  src="/assets/gea_logo_login.jpg"
                />
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col>
                <Text type="secondary">Hygiene App Administration</Text>
              </Col>
            </Row>

            <Form layout={"vertical"} style={{marginTop: 50}}>
              <Form.Item
                style={{marginBottom: 0, paddingBottom: 10}}
                colon={false}
                label="Email">
                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
              <Form.Item
                style={{marginTop: 0, paddingTop: 0}}
                colon={false}
                label="Password">
                <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="button" style={{borderRadius: 5}} onClick={() => performLogin()} block>
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Login;