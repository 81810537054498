import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  List,
  Divider,
  notification
} from 'antd';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import AddDialog from '../../forms/AddDialog';
import ButtonActions from '../../common/ButtonActions';
import Api from '../../../services/Api';

const { Text } = Typography;

function ProductLabels(props) {
  const [labels, setLabels] = useState([]);
  const [showLabelAdd, setShowLabelAdd] = useState(false);
  // const [lastFetchId, setLastFetchId] = useState(0);
  // const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    if(props.existingLabels) {
      let sortedLabels = props.existingLabels.sort((a, b) => a.position - b.position);
      setLabels(sortedLabels);
    }
  }, [])
  
  const handleNameOnClick = async (type, value, item, files, translations) => {
    const translationDictionary = {};

    translations && translations.forEach(item => {
      translationDictionary[item.language] = item.translation;
    });

    if(type === 'cancel') {
      setShowLabelAdd(false);

      if(props.onClick != null) {
        props.onClick(ButtonActions.CANCEL, [])
      }
    } else if(type === 'add') {
      if(labels.length < 4) {
        setLabels([...labels, {id: new Date().getTime(), label_name: value, label_name_translations: translationDictionary }]);
        setShowLabelAdd(false);
  
        if(props.onClick != null) {
          props.onClick(ButtonActions.ADD, [...labels, {id: new Date().getTime(), label_name: value, label_name_translations: translationDictionary }])
        }
      } else {
        notification.error({
          message: 'Tag Error',
          description: 'You can enter up to 4 tags per product.',
          placement: 'bottomRight'
        });
      }
    }
  }

  const deleteLabel = (index) => {
    let newLabels = [...labels];
    newLabels.splice(index, 1);

    setLabels(newLabels);

    if(props.onClick != null) {
      props.onClick(ButtonActions.DELETE, newLabels);
    }
  }

  const SortableItem = SortableElement(({value}) => {
    return <List.Item className="sortable-item">
      <Text>{value}</Text>
    </List.Item>
  });

  const SortableList = SortableContainer(({items}) => {
    return (
      <List bordered={true} className="sortable-container">
        {items.map((item, index) => (
          <div key={`item-${index}`} style={{position: 'relative'}}>
            <SortableItem key={`item-${index}`} index={index} value={item.label_name} />
            <DeleteOutlined style={{position: 'absolute', right: 10, top: 15, color: '#f00'}} onClick={() => deleteLabel(index)} />
          </div>
        ))}
      </List>
    );
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    const newLabels = arrayMove(labels, oldIndex, newIndex);
    setLabels(newLabels);

    if(props.onClick != null) {
      props.onClick(ButtonActions.ADD, newLabels);
    }
  };

  const saveProductLabels = async () => {
    let itemsToAdd = [];

    labels.map((item, index) => {
      itemsToAdd.push({
        label_name: item.label_name,
        position: index + 1,
        label_name_translations: item.label_name_translations
      });
    });

    const { data } = await Api.post(`products/${props.productId}/labels/batch_upsert`, itemsToAdd);

    if(data.message) {
      notification.success({
        message: 'Tags Success',
        description: 'The tags has been created successfully!',
        placement: 'bottomRight'
      });
      setShowLabelAdd(false);
    } else {
      notification.error({
        message: 'Tags Error',
        description: 'There was a problem creating these tags. Please try again or contact support.',
        placement: 'bottomRight'
      })
    }
  }

  return (
    <Row style={{padding: 20}} justify={"start"}>
      <Col span={24} style={{textAlign: 'left'}}>
        <Text><Text strong>Product Tags</Text> - add tags to a product</Text><br/>
        <Text>These tags will appear against the product in search results and product listings.</Text>
        <Row style={{marginTop: 20}}>
          <Col span={24}>
            <Button type="primary" icon={<PlusCircleOutlined />} size={20} onClick={() => setShowLabelAdd(true)}>Add New Tag</Button>
            <div style={{marginTop: 20}}>
              To change the order and position of the tags, simply drag the label and move them into the preferred position.<br/>
              <Text type="warning">Please note, we will be allowing up to 4 tags only due to space limitations.</Text>
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: 20}}>
          <Col span={12}>
            {
              labels.length > 0 ? <SortableList items={labels} onSortEnd={(e) => onSortEnd(e)} /> : null
            }
          </Col>
        </Row>
        {
          showLabelAdd ? <AddDialog 
            modalTitle={"Add new tag"} 
            nameLabel={"Name of tag"} 
            placeholderDescription={"Enter name"} 
            errorTitle={"Tag Error"} 
            errorDescription={"The tag name needs to be entered."}
            showNameTranslation={true}
            onAddClick={(type, value, item, files, translations) => handleNameOnClick(type, value, item, [], translations)} /> : null
        }
      </Col>
      <Divider />
      {
        props.hideSaveDetails ? null :
          <Col>
            <Button type="primary" onClick={() => saveProductLabels()}>
              <SaveOutlined /> Save Tags
            </Button>
          </Col>
      }
    </Row>
  )
}

export default ProductLabels