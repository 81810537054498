import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card, 
  Typography,
  notification,
} from 'antd';
import { ExportOutlined, UnlockOutlined } from '@ant-design/icons';
import axios from 'axios';
import Utils from '../common/Utils';
import PageRoutes from '../../services/PageRoutes';
import queryString from 'query-string';

const { Text } = Typography;

function ResetPassword(props) {
  const [resetToken, setResetToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  useEffect(() => {
    var reset_token = '';

    const values = queryString.parse(props.location.search)

    if(values.reset_token) {
      reset_token = values.reset_token
    }

    setResetToken(reset_token);
  }, []);

  const performReset = () => {
    if(password && passwordConfirm) {
      if(password.length > 7) {
        if(password === passwordConfirm) {
          axios.post(`${process.env.REACT_APP_API_URL}users/reset_password`, {
            reset_token: resetToken,
            password: password,
            password_confirmation: passwordConfirm
          })
          .then(res => {
            notification.success({
              duration: 2,
              message: 'Reset Password',
              description: 'Your password has been successfuly reset. We will now direct you to the login screen to log back in with your new details.',
              placement: 'bottomRight',
              onClose: () => {
                props.history.replace(PageRoutes.Login);
              },
              onClick: () => {
                props.history.replace(PageRoutes.Login);
              },
            });
          })
          .catch(error => {
            console.log('error', error);
            notification.error({
              message: 'Reset Password Error',
              description: 'There was a problem updating your password, the reset link in the email may have expired or the token is invalid.',
              placement: 'bottomRight'
            });
          })
        } else {
          notification.error({
            message: 'Reset Password Error',
            description: 'The passwords do not match, please try again.',
            placement: 'bottomRight'
          });
        }
      } else {
        notification.error({
          message: 'Reset Password Error',
          description: 'The minimum password length is 8 characters.',
          placement: 'bottomRight'
        });
      }
    } else {
      notification.error({
        message: 'Reset Password Error',
        description: 'The passwords both need to be filled in.',
        placement: 'bottomRight'
      });
    }
  }
  
  return (
    <div style={{backgroundColor: '#eee'}}>
      <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
        <Col span={4}>
          <Card
            style={{ width: 400, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "5px 8px 24px 5px rgba(0, 0, 0, 0.1)" }}
            actions={[
              <Text onClick={() => props.history.push(PageRoutes.Login)}><ExportOutlined key="forgot_password" style={{paddingRight: 10}} /> Sign In</Text>,
            ]}
          >
            <Row type="flex" justify="center" style={{paddingTop: 20}}>
              <Col>
                <img
                  width="200"
                  alt="GEA"
                  src="/assets/gea_logo_login.jpg"
                />
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col>
                <Text type="secondary">Hygiene App Administration</Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Text type="primary" strong={true}>Reset Password</Text>
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col>
                <Text type="secondary">To reset your password, please enter a new password.</Text>
              </Col>
            </Row>
            <Form layout={"vertical"} style={{marginTop: 20}}>
              <Form.Item
                style={{marginBottom: 0, paddingBottom: 10}}
                colon={false}
                label="Enter new password">
                <Input type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} />
              </Form.Item>
              <Form.Item
                style={{marginBottom: 0, paddingBottom: 10}}
                colon={false}
                label="Enter new password again">
                <Input type={"password"} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="button" style={{borderRadius: 5}} onClick={() => performReset()} block>
                  RESET PASSWORD
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ResetPassword
