import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card, 
  Typography,
  notification,
} from 'antd';
import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, DeleteOutlined, ExportOutlined, UnlockOutlined } from '@ant-design/icons';
import axios from 'axios';
import Utils from '../common/Utils';
import PageRoutes from '../../services/PageRoutes';
import queryString from 'query-string';

const { Text } = Typography;

function Activate(props) {
  const [activationText, setActivationText] = useState('');
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    var activationToken = '';
    const values = queryString.parse(props.location.search)

    if(values.activation_token) {
      activationToken = values.activation_token
    }

    processActivationToken(activationToken);
  }, []);

  const processActivationToken = (activationToken) => {
    if(activationToken && activationToken.length > 0) {
      axios.post(`${process.env.REACT_APP_API_URL}users/activate`, {
        activation_token: activationToken
      })
      .then(res => {
        setActivationText('Your account has been activated, you can now logon to the GEA Hygiene Mobile App.');
        setActivated(true);
      })
      .catch(error => {
        console.log('error', error);
        setActivationText('Your account could not be activated. The activation token is either invalid, already activated or has expired, please contact support.');
      })
    } else {
      setActivationText('Your account could not be activated. The activation token entered is not valid.');
    }
  }
  
  return (
    <div style={{backgroundColor: '#eee'}}>
      <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
        <Col span={4}>
          <Card style={{ width: 400, borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "5px 8px 24px 5px rgba(0, 0, 0, 0.1)" }}>
            <Row type="flex" justify="center" style={{paddingTop: 20}}>
              <Col>
                <img
                  width="200"
                  alt="GEA"
                  src="/assets/gea_logo_login.jpg"
                />
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col>
                <Text type="secondary">Hygiene App</Text>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Text type="primary" strong={true}>Mobile Account Activation</Text>
              </Col>
            </Row>
            <Row justify="center" style={{paddingTop: 20}}>
              <Col style={{textAlign: 'center'}}>
                <Text type="secondary">{activationText}</Text>
              </Col>
            </Row>
            {
              activated ? <Row justify="center" style={{marginTop: 30}}>
                <Col>
                  <CheckCircleOutlined style={{fontSize: 30, color: '#00b300'}} />
                </Col>
              </Row> :
              <Row justify="center" style={{marginTop: 30}}>
                <Col>
                  <CloseCircleOutlined style={{fontSize: 30, color: '#f00'}} />
                </Col>
              </Row>
            }
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Activate
