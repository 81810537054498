import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Switch,
  message,
  notification,
  Row,
  Steps,
  Table, 
  Col,
  Typography,
  Drawer,
  Card, 
  Checkbox,
  Tabs,
  Upload,
  Modal, Skeleton, Tree
} from 'antd';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { CheckOutlined, SafetyCertificateOutlined, LeftOutlined, RightOutlined, TrademarkOutlined, TagsOutlined, CompassOutlined, FilterOutlined, PlusCircleOutlined, DeleteOutlined, RedoOutlined, InboxOutlined, FileImageOutlined, CheckCircleOutlined, BookOutlined, BookFilled, BookTwoTone } from '@ant-design/icons';

import MainLayout from '../../common/MainLayout';
import Api from '../../../services/Api';
import ProductDocumentDialog from './ProductDocumentDialog';
import ButtonActions from '../../common/ButtonActions';
import ProductCheckboxType from '../../common/ProductCheckboxType';
import Utils from '../../common/Utils';
import ProductFileType from '../../common/ProductFileType';
import PageRoutes from '../../../services/PageRoutes';
import TokenStorage from '../../../services/TokenStorage';
import ProductLabels from './ProductLabels';
import ProductSerialNumber from './ProductSerialNumber';
import AddTranslation from '../../forms/AddTranslation';
import ContentLoader from '../../common/ContentLoader';

const { Step } = Steps;
const { Text } = Typography;
const { TabPane } = Tabs;
const { Dragger } = Upload;

const modules = {
  toolbar: [
    [{ 'header': [3, 4, false] }],
    ['bold', 'italic'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['clean']
  ],
}

const formats = [
  'header',
  'bold', 'italic', 
  'list', 'bullet',
]

function CreateProduct(props) {
  const [productId, setProductId] = useState(0);
  const [tempDocId, setTempDocId] = useState(1000000); //TODO: Remove this
  // step 1
  const [current, setCurrent] = useState(0);
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [useLabels, setUseLabels] = useState(false);
  const [nameTranslations, setNameTranslations] = useState([]);
  const [summaryTranslations, setSummaryTranslations] = useState([]);
  const [descriptionTranslations, setDescriptionTranslations] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [showNameTranslation, setShowNameTranslation] = useState(false);
  const [showSummaryTranslation, setShowSummaryTranslation] = useState(false);
  const [showDescriptionTranslation, setShowDescriptionTranslation] = useState(false);
  // step 2
  const [cancel, setCancel] = useState(false);
  const [activeIngredients, setActiveIngredients] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedActiveIngredients, setSelectedActiveIngredients] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [categoryTree, setCategoryTree] = useState([]);
  // step 3
  const [showForm, setShowForm] = useState(false);
  const [types, setTypes] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentCountries, setDocumentCountries] = useState({});
  // step 4
  const [imageFileList, setImageFileList] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  // step 5
  const [labels, setLabels] = useState([]);

  const [categoriesLoaded, setCategoriesLoaded] = useState(true);
  const [activeIngredientsLoaded,setActiveIngredientsLoaded] = useState(true);
  const [brandsLoaded, setBrandsLoaded] = useState(true);
  const [countriesLoaded, setCountriesLoaded] = useState(true);
  const [filtersLoaded, setFiltersLoaded] = useState(true);
  const [typesLoaded, setTypesLoaded] = useState(true);

  const imageFileProps = {
    name: 'file',
    multiple: false,
    showUploadList: {
      showDownloadIcon: false,
    },
    onRemove: file => {
      setImageFileList([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`${file.name} is not a png or jpg file`);
      } else {
        setImageFileList([file]);
        setPreviewImage(URL.createObjectURL(file));
        return false;
      }
    },
    imageFileList
  }

  useEffect(() => {
    const retrieveCategories = async () => {
      try {
        const { data } = await Api.get('categories/list?all=true');
        setCategories(data.categories);
        generateTree(data.categories);
        setCategoriesLoaded(false);
      } catch(error) {
        setCategoriesLoaded(false);
      }
    }

    const retrieveActiveIngredients = async () => {
      try {
        const { data } = await Api.get('active_ingredients/list');
        setActiveIngredients(data.active_ingredients);
        setActiveIngredientsLoaded(false);
      } catch(error) {
        setActiveIngredientsLoaded(false);
      }
    }

    const retrieveBrands = async () => {
      try {
        const { data } = await Api.get('brands/list');
        setBrands(data.brands);
        setBrandsLoaded(false);
      } catch(error) {
        setBrandsLoaded(false);
      }
    }

    const retrieveCountries = async () => {
      try {
        const { data } = await Api.get('countries/list');
        setCountries(data.countries);
        setCountriesLoaded(false);
      } catch(error) {
        setCountriesLoaded(false);
      }
    }

    const retrieveFilters = async () => {
      try {
        const { data } = await Api.get('filters/list');
        setFilters(data.filters);
        setFiltersLoaded(false);
      } catch(error) {
        setFiltersLoaded(false);
      }
    }

    const retrieveTypes = async () => {
      try {
        const { data } = await Api.get('types/list');
        setTypes(data.types);
        setTypesLoaded(false);
      } catch(error) {
        setTypesLoaded(false);
      }
    }

    retrieveCategories();
    retrieveActiveIngredients();
    retrieveBrands();
    retrieveCountries();
    retrieveFilters();
    retrieveTypes();

  }, []);

  const steps = [
    {
      title: 'Details',
      description: 'The main details of the product',
    },
    {
      title: 'Attributes',
      description: 'The different attributes of the product',
    },
    {
      title: 'Tags',
      description: 'Any tags for the product',
    },
    {
      title: 'Documents',
      description: 'Attach documents related to the product'
    },
    {
      title: 'Image',
      description: 'Attach an image to the product'
    }
  ];

  const setup = async () => {
    if(current === 0 && productId === 0) {
      let hasErrors = true;

      if(name && description) {
        if(name.trim().length > 0 && description.trim().length > 0) {
          hasErrors = false;
        }
      }

      if(!hasErrors) {
        const nameTranslationDictionary = {};
        const summaryTranslationDictionary = {};
        const descriptionTranslationDictionary = {};
        const serialNumberArray = [];

        nameTranslations && nameTranslations.forEach(item => {
          nameTranslationDictionary[item.language] = item.translation;
        });

        summaryTranslations && summaryTranslations.forEach(item => {
          summaryTranslationDictionary[item.language] = item.translation;
        });

        descriptionTranslations && descriptionTranslations.forEach(item => {
          descriptionTranslationDictionary[item.language] = item.translation;
        });

        serialNumbers && serialNumbers.forEach(item => {
          serialNumberArray.push(item.serial_number);
        });
        
        try {
          var createData = {
            name: name,
            summary: summary,
            description: description,
            use_labels: useLabels,
            name_translations: nameTranslationDictionary,
            summary_translations: summaryTranslationDictionary,
            description_translations: descriptionTranslationDictionary,
            serial_numbers: serialNumberArray
          }

          const { data } = await Api.post('products/create', createData);
  
          setProductId(data.id);
          setCurrent(current + 1);
  
        } catch(error) {
          notification.error({
            message: 'Product Error',
            description: 'There was a problem creating this product. Please make sure the name and description are filled in and try again or contact support.',
            placement: 'bottomRight'
          });
        }
      } else {
        notification.error({
          message: 'Product Error',
          description: 'Please make sure the name and description are completed.',
          placement: 'bottomRight'
        });
      }
    }
  }

  const next = () => {
    setCurrent(current + 1);
  }

  const prev = () => {
    setCurrent(current - 1);
  }

  const finish = async () => {
    if(imageFileList.length === 0) {
      notification.error({
        message: 'Product Image Error',
        description: 'The product requires an image to be attached. Please try again.',
        placement: 'bottomRight'
      });    
    } else {
      let attributes = {};
      let hasErrors = false;

      attributes['active_ingredients'] = [];
      attributes['brands'] = [];
      attributes['categories'] = [];
      attributes['filters'] = [];
      attributes['countries'] = [];
  
      selectedActiveIngredients.map(item => attributes['active_ingredients'].push({ active_ingredient_id: item }));
      selectedBrands.map(item => attributes['brands'].push({ brand_id: item }));
      selectedCategories.map(item => attributes['categories'].push({ category_id: item }));
      selectedFilters.map(item => attributes['filters'].push({ filter_id: item }));
      selectedCountries.map(item => attributes['countries'].push({ country_id: item }));
  
      // update product attributes
      try { 
        const { data } = await Api.put(`products/${productId}/update_attributes`, attributes);
      } catch(error) {
        hasErrors = true;
      }

      // insert the product documents
      documents.map(async (item) => {
        var file = item.fileList[0];
        var document = URL.createObjectURL(file);
  
        fetch(document)
        .then(async (res) => res.blob())
        .then(async (blob) => {
          var options = {
            method: 'POST',
            headers: {
              "Api-Arg": JSON.stringify({
                type_id: Utils.getDocumentTypeId(types, item.typeName),
                name: item.name,
                file_type: Utils.getFileExtension(file.name)
              }),
              "Content-Type": file.type,
              "Authorization": `JWT ${TokenStorage.getToken()}`
            },
            data: blob,
            url: `${process.env.REACT_APP_API_URL}products/${productId}/upload`
          }
  
          try {
            const { data } = await axios(options);

            if(data && data.id > 0) {
              let itemsToAdd = [];

              let currentDocumentCountries = documentCountries[item.id];

              if(currentDocumentCountries.length > 0) {
                currentDocumentCountries.map((documentItem, index) => {
                  itemsToAdd.push({
                    document_id: data.id,
                    country_id: documentItem
                  });
                });
              }

              await Api.post(`documents/${data.id}/countries/batch_upsert`, itemsToAdd);
            }
          } catch(error) {
            hasErrors = true;
          }
        });
      });
      
      imageFileList.map(async (item) => {
        var img = URL.createObjectURL(item);
        fetch(img)
        .then(async (res) => res.blob())
        .then(async (blob) => {
          var options = {
            method: 'POST',
            headers: {
              "Api-Arg": JSON.stringify({
                type_id: Utils.getDocumentTypeId(types, ProductFileType.PRODUCT_IMAGE),
                name: item.name,
                file_type: Utils.getFileExtension(item.name)
              }),
              "Content-Type": item.type,
              "Authorization": `JWT ${TokenStorage.getToken()}`
            },
            data: blob,
            url: `${process.env.REACT_APP_API_URL}products/${productId}/upload_image`
          }

          try {
            const { data } = await axios(options);
          } catch(error) {
            hasErrors = true;
          }
        });
      });

      if(labels) {
        let itemsToAdd = [];

        labels.map((item, index) => {
          itemsToAdd.push({
            label_name: item.label_name,
            position: index + 1,
            label_name_translations: item.label_name_translations
          });
        });
        
        const { data } = await Api.post(`products/${productId}/labels/batch_upsert`, itemsToAdd);
      }

      if(!hasErrors) {
        setShowSuccess(true); 
      } else {
        notification.error({
          message: 'Product Error',
          description: 'There was a problem while trying to create this product. Please contact support.',
          placement: 'bottomRight'
        });
      }
    }
  }

  const startAgain = () => {
    setProductId(0);
    setCurrent(0);
    setName('');
    setSummary('');
    setDescription('');
    setSelectedActiveIngredients([]);
    setSelectedBrands([]);
    setSelectedCategories([]);
    setSelectedFilters([]);
    setSelectedCountries([]);
    setShowForm(false);
    setTypes([]);
    setDocuments([]);
    setImageFileList([]);
    setSerialNumbers([]);
  }

  const deleteDocumentRow = (rowId) => {
    const newDocuments = [...documents];
    newDocuments.splice(newDocuments.findIndex(item => item.id === rowId), 1);
    setDocuments(newDocuments);

    delete documentCountries[rowId];
  }

  const documentColumns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row key={'doc_' + index}>
          <Col>
            <Button type="danger" size="small" onClick={() => deleteDocumentRow(row.id)}>
              <DeleteOutlined /> 
            </Button>
          </Col>
        </Row>
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, row, index) => {
        return <Row key={'doc_' + index}>
          <Col>
            <Text>{row.typeName.replace('_DOCUMENT', '')}</Text>
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
  ];

  const showDrawer = () => {
    setShowForm(true);
  };

  const closeDrawer = () => {
    setShowForm(false);
  };

  const updateProductCheckbox = (type, data, checked) => {
    switch(type) {
      case ProductCheckboxType.FILTER:
        if(checked) {
          let filteredList = [...selectedFilters];
          if(filteredList.indexOf(data.id) == -1) {
            setSelectedFilters([...selectedFilters, data.id]);
          }
        } else {
          const newList = [...selectedFilters];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedFilters(newList)
        }
        break;
      case ProductCheckboxType.BRAND:
        if(checked) {
          let filteredList = [...selectedBrands];
          if(filteredList.indexOf(data.id) == -1) {
            setSelectedBrands([...selectedBrands, data.id]);
          }
        } else {
          const newList = [...selectedBrands];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedBrands(newList)
        }
        break;
      case ProductCheckboxType.ACTIVE_INGREDIENT:
        if(checked) {
          let filteredList = [...selectedActiveIngredients];
          if(filteredList.indexOf(data.id) == -1) {
            setSelectedActiveIngredients([...selectedActiveIngredients, data.id]);
          }
        } else {
          const newList = [...selectedActiveIngredients];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedActiveIngredients(newList)
        }
        break;
      case ProductCheckboxType.COUNTRY:
        if(checked) {
          let filteredList = [...selectedCountries];
          if(filteredList.indexOf(data.id) == -1) {
            setSelectedCountries([...selectedCountries, data.id]);
          }
        } else {
          const newList = [...selectedCountries];
          newList.splice(newList.findIndex(item => item.id === data.id), 1)
          setSelectedCountries(newList)
        }
        break;
      case ProductCheckboxType.CATEGORY:
        if(checked) {
          let filteredList = [...selectedCategories];
          if(filteredList.indexOf(data.id) == -1) {
            setSelectedCategories([...selectedCategories, data.id]);
          }
        } else {
          const newList = [...selectedCategories];
          newList.splice(newList.findIndex(item => item === data.id), 1)
          setSelectedCategories(newList)
        }
        break;
      default:
        break;
    }
  }

  const handleDocumentDialog = async (type, form, savedCountries) => {
    if(type === ButtonActions.CANCEL) {
      closeDrawer();
    } else if (type === ButtonActions.SUBMIT) {
      form.id = tempDocId;

      setDocumentCountries({ ...documentCountries, [tempDocId]: savedCountries });
      setDocuments([...documents, form]);
      setTempDocId(tempDocId + 1);
      closeDrawer();
    }
  }

  const onCategoryCheck = (selectedKeys, info) => {
    setSelectedCategories(selectedKeys);
  }

  const generateTree = (categories) => {
    let categoryTree = [];

    if(categories) {
      let parentCategories = categories.filter(item => { return item.parent_id === null});

      for(var i = 0; i < parentCategories.length; i++) {
        let parentCategory = parentCategories[i];

        let children = [];
        let subCategoryList = categories.filter(item => { return item.parent_id === parentCategory.id });

        for(var j = 0; j < subCategoryList.length; j++) {
          let subCategory = subCategoryList[j];

          children.push({
            title: subCategory.name,
            key: subCategory.id
          })
        }

        categoryTree.push({
          title: parentCategory.name,
          key: parentCategory.id,
          children: children
        })
      }
    }

    setCategoryTree(categoryTree);
  }

  const handleProductLabels = (type, labelList) => {
    setLabels(labelList);
  }

  const handleProductSerialNumbers = (serialNumbers) => {
    setSerialNumbers(serialNumbers);
  }

  const handleNameTranslationsClick = () => {
    setShowNameTranslation(true)
  }

  const handleSummaryTranslationsClick = () => {
    setShowSummaryTranslation(true)
  }

  const handleDescriptionTranslationsClick = () => {
    setShowDescriptionTranslation(true)
  }

  const handleNameTranslations = (action, translations) => {
    if(action !== 'cancel') {
      setNameTranslations(translations)
    }

    setShowNameTranslation(false)
  }

  const handleSummaryTranslations = (action, translations) => {
    if(action !== 'cancel') {
      setSummaryTranslations(translations)
    }

    setShowSummaryTranslation(false)
  }

  const handleDescriptionTranslations = (action, translations) => {
    if(action !== 'cancel') {
      setDescriptionTranslations(translations)
    }

    setShowDescriptionTranslation(false)
  }
  
  return (
    <>
      {
        (categoriesLoaded && activeIngredientsLoaded && brandsLoaded && filtersLoaded && countriesLoaded && typesLoaded) ? <ContentLoader /> :
        <MainLayout pageTitle={'Create New Product'} pageIconTitle={'products'} hideCreateButton={true} hideBackButton={true} {...props}>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content" style={{paddingBottom: 20}}>
            
            <Row style={{padding: 20}} justify={"start"} hidden={current !== 0}>
              <Col span={24} style={{textAlign: 'left'}}>
                <Text><Text strong>Product Details</Text> - the main details of the product</Text>
                <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
                  <Form.Item label="Name">
                    <Input style={{width: 300}} value={name} onChange={(e) => setName(e.target.value)} />
                    <Button style={{ marginLeft: 10 }} type="primary" icon={<BookOutlined />} size={20} onClick={() => handleNameTranslationsClick()}>Name Translations ({nameTranslations.length})</Button>
                  </Form.Item>
                  <Form.Item label="Summary">
                    <Input  value={summary} onChange={(e) => setSummary(e.target.value)} />
                    <br/>
                    <Button style={{ marginTop: 10 }} type="primary" icon={<BookOutlined />} size={20} onClick={() => handleSummaryTranslationsClick()}>Summary Translations ({summaryTranslations.length})</Button>
                  </Form.Item>
                  <Form.Item label="Use Tags">
                    <div>
                      <Text>If "use tags" is switched on, then these tags will be shown in the product listings & search results.</Text>
                    </div>
                    <div style={{marginTop: 20}}>
                      <Switch onChange={(checked) => setUseLabels(checked)} defaultChecked={useLabels}  />
                    </div>
                  </Form.Item>
                  <Form.Item label="Description">
                    <ReactQuill 
                      theme="snow" 
                      modules={modules}
                      formats={formats}
                      value={description} 
                      onChange={(value) => setDescription(value)}
                      style={{
                        height: 350,
                        backgroundColor: '#fff'
                      }} />
                    <br/>
                  </Form.Item>
                  <Button style={{ marginTop: 10 }} type="primary" icon={<BookOutlined />} size={20} onClick={() => handleDescriptionTranslationsClick()}>Description Translations ({descriptionTranslations.length})</Button>
                  
                </Form>
                <Row style={{ marginTop: 20 }}>
                  <Text strong>Serial Numbers</Text>
                  <Col span={24} style={{ marginTop: 5 }}>
                    <ProductSerialNumber 
                      serialNumbersUpdated={handleProductSerialNumbers} 
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{padding: 20}} justify={"start"} hidden={current !== 1}>
              <Col span={24} style={{textAlign: 'left'}}>
                <Text><Text strong>Product Attributes</Text> - the different attributes of the product</Text><br/><br/>
                <Text>Choose the product attribute below and mark the properties that are applicable to the product.</Text>
                <div style={{marginTop: 50, marginLeft: 0}}>
                  <Tabs tabPosition={'left'} style={{marginLeft: 0}}>
                    <TabPane 
                      tab={
                        <span>
                          <SafetyCertificateOutlined />
                          Active Ingredients
                        </span>
                      } key="1">
                      <Card title={<span><SafetyCertificateOutlined /> <span style={{paddingLeft: 5}}>Active Ingredients</span></span>} style={{minHeight: 400}}>
                        <Text>Check all the active ingredients that apply to this product.</Text><br/><br/>
                        {activeIngredients.map(item => (
                          <div key={'ai_' + item.id}>
                            <Checkbox onChange={(chk) => updateProductCheckbox(ProductCheckboxType.ACTIVE_INGREDIENT, item, chk.target.checked)}>{item.name}</Checkbox><br/>
                          </div>
                        ))}
                      </Card>
                    </TabPane>
                    <TabPane 
                      tab={
                        <span>
                          <TrademarkOutlined />
                          Brands
                        </span>
                      }
                      key="2">
                      <Card title={<span><TrademarkOutlined /> <span style={{paddingLeft: 5}}>Brands</span></span>} style={{minHeight: 400}}>
                        <Text>Check all the brands that apply to this product.</Text><br/><br/>
                        {brands.map(item => (
                          <div key={'b_' + item.id}>
                            <Checkbox onChange={(chk) => updateProductCheckbox(ProductCheckboxType.BRAND, item, chk.target.checked)}>{item.name}</Checkbox><br/>
                          </div>
                        ))}
                      </Card>
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <TagsOutlined />
                          Categories
                        </span>
                      }
                      key="3">
                      <Card title={<span><TagsOutlined /> <span style={{paddingLeft: 5}}>Categories</span></span>} style={{minHeight: 400}}>
                        <Text>Check all the categories that apply to this product.</Text><br/><br/>
                        <div>
                          <Tree
                            checkable={true}
                            selectable={false}
                            defaultExpandAll={true}
                            onCheck={(selectedKeys, info) => onCategoryCheck(selectedKeys, info)}
                            checkedKeys={selectedCategories}
                            treeData={categoryTree}
                          />
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane 
                      tab={
                        <span>
                          <CompassOutlined />
                          Countries
                        </span>
                      } key="4">
                      <Card title={<span><CompassOutlined /> <span style={{paddingLeft: 5}}>Countries</span></span>} style={{minHeight: 400}}>
                        <Text>Check all the countries that apply to this product. If a country is not available, enable it under <b>Settings &gt; Countries</b>.</Text><br/><br/>
                        {
                        countries.filter(function(country) {
                          return country.active;
                        }).map(item => (
                          <div key={'c_' + item.id}>
                            <Checkbox onChange={(chk) => updateProductCheckbox(ProductCheckboxType.COUNTRY, item, chk.target.checked)}>{item.name}</Checkbox><br/>
                          </div>
                        ))}
                      </Card>
                    </TabPane>
                    <TabPane 
                      tab={
                        <span>
                          <FilterOutlined />
                          Filters
                        </span>
                      } key="5">
                      <Card title={<span><FilterOutlined /> <span style={{paddingLeft: 5}}>Filters</span></span>} style={{minHeight: 400}}>
                        <Text>Check all the filters that apply to this product.</Text><br/><br/>
                        {filters.map(item => (
                          <div key={'f_' + item.id}>
                            <Checkbox onChange={(chk) => updateProductCheckbox(ProductCheckboxType.FILTER, item, chk.target.checked)}>{item.name}</Checkbox><br/>
                          </div>
                        ))}
                      </Card>
                    </TabPane>
                  </Tabs>
                </div>
              </Col>
            </Row>

            <Row style={{padding: 20}} justify={"start"} hidden={current !== 2}>
              <Col span={24} style={{textAlign: 'left'}}>
                <ProductLabels hideSaveDetails={true} onClick={(type, labelList) => handleProductLabels(type, labelList)} productId={productId} />
              </Col>
            </Row>

            <Row style={{padding: 20}} justify={"start"} hidden={current !== 3}>
              <Col span={24} style={{textAlign: 'left'}}>
                <Text><Text strong>Product Documents</Text> - attach documents related to the product</Text>
                <Row style={{marginTop: 20}}>
                  <Col span={24}>
                    <Button type="primary" icon={<PlusCircleOutlined />} size={20} onClick={() => showDrawer()}>Add New Document</Button>
                  </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                  <Col span={24}>
                    <Table dataSource={documents} columns={documentColumns} showSorterTooltip={false} rowKey="id">
                    </Table>
                  </Col>
                </Row>
                {
                  showForm ? <Drawer
                    title="Add a new document"
                    width={720}
                    onClose={() => closeDrawer()}
                    visible={showForm}
                    bodyStyle={{ paddingBottom: 80 }}>
                    <ProductDocumentDialog 
                      documentTypes={types} 
                      countries={countries}
                      onClick={(type, form, selectedCountries) => handleDocumentDialog(type, form, selectedCountries)} />
                  </Drawer> : null
                }
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{padding: 20}} justify={"start"} hidden={current !== 4}>
              <Col span={24} style={{textAlign: 'left'}}>
                <Text><Text strong>Product Image</Text> - attach an image to the product</Text>
                <Form layout="vertical" style={{textAlign: 'start', paddingTop: 10}}>
                  <Form.Item label="Select image and upload">
                    <div style={{marginBottom: 10}}>
                      <Text>Image sizes are recommended to be square for optimal display, e.g 400x400, 512x512, 1280x1280, etc.</Text>
                    </div>
                    <Dragger {...imageFileProps} fileList={imageFileList} showUploadList={true}>
                      <p className="ant-upload-drag-icon">
                        <FileImageOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag an image to this area to upload</p>
                      <p className="ant-upload-hint">
                        We will only support png or jpg images.
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Form.Item label="Upload image preview">
                    {
                      previewImage ? <img src={previewImage} alt="" style={{width: 200, height: 200}} /> : <Skeleton.Image style={{width: 200, height: 200}} />
                    }
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
          <div className="steps-action">
            {
              current === 0 && (
                <Button type="primary" onClick={() => setup()}>
                  Next <RightOutlined />
                </Button>
              )
            }
            {
              current === 1 && (
                <div>
                  <Button style={{ margin: '0 8px' }} onClick={() => startAgain()}>
                    <RedoOutlined /> Start Again 
                  </Button>
                  <Button type="primary" onClick={() => next()}>
                    Next <RightOutlined />
                  </Button>
                </div>
              )
            }
            {
              (current === 2 || current === 3) && (
                <div>
                  <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                    <LeftOutlined /> Previous
                  </Button>
                  <Button type="primary" onClick={() => next()}>
                    Next <RightOutlined />
                  </Button>
                </div>
              )
            }
            {
              current === 4 && (
                <div>
                  <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                    <LeftOutlined /> Previous
                  </Button>
                  <Button type="primary" onClick={() => finish()}>
                  <CheckOutlined /> Done 
                  </Button>
                </div>
              )
            }
          </div>
          <Modal
            title={ <Text><CheckCircleOutlined style={{color: "green", marginRight: 10}} />New Product Added</Text>}
            style={{ top: 20 }}
            closable={false}
            visible={showSuccess}
            onOk={() => setShowSuccess(false)}
            onCancel={null}
            footer={[
              <Button key="ok" onClick={() => props.history.push(PageRoutes.Products)}>
                OK
              </Button>,
            ]}
          >
            <p>The product has been created successfully!</p>
            <p>To make changes to the product, you can edit them from the products listing page.</p>
          </Modal>

          {
            showNameTranslation ? <AddTranslation 
              modalTitle={"Add name translations"} 
              nameLabel={"Enter name translation *"} 
              existingTranslations={nameTranslations}
              onAddClick={(action, translations) => handleNameTranslations(action, translations)} /> : null
          }

          {
            showSummaryTranslation ? <AddTranslation 
              modalTitle={"Add summary translations"} 
              nameLabel={"Enter summary translation *"} 
              existingTranslations={summaryTranslations}
              onAddClick={(action, translations) => handleSummaryTranslations(action, translations)} /> : null
          }

          {
            showDescriptionTranslation ? <AddTranslation 
              modalTitle={"Add description translations"} 
              nameLabel={"Enter description translation *"} 
              existingTranslations={descriptionTranslations}
              useTextArea={true}
              onAddClick={(action, translations) => handleDescriptionTranslations(action, translations)} /> : null
          }
        </MainLayout>
      }
    </>
  )
}


export default CreateProduct