import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Breadcrumb, Button, Col, notification, Popconfirm, Row, Skeleton, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import { useLocation, useParams } from 'react-router-dom';

import MainLayout from '../../common/MainLayout';
import AddDialog from '../../forms/AddDialog';
import Api from '../../../services/Api';
import PageRoutes from '../../../services/PageRoutes';
import Utils from '../../common/Utils';
import ProductFileType from '../../common/ProductFileType';
import TokenStorage from '../../../services/TokenStorage';

function SubCategories(props) {
  const [showAdd, setShowAdd] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [selectedItem, setSelectedItem] = useState({});

  const { categoryId } = useParams();

  useEffect(() => {
    let cancel = false;

    retrieveCategories();

    return () => {
      cancel = true;
    }
  }, []);

  const retrieveCategories = () => {
    const retrieveSubCategories = async () => {
      const { data } = await Api.get(`categories/list?parent_category_id=${categoryId}`);
      setSubCategories(data.categories);
    }

    const retrieveCategory = async () => {
      const { data } = await Api.get(`categories/${categoryId}`);
      setCategory(data);
    }
    
    retrieveSubCategories();
    retrieveCategory();
  }

  const deleteRow = async (rowId) => {
    try {
      const { data } = await Api.delete(`categories/${rowId}/delete`);

      const newSubCategories = [...subCategories];
      newSubCategories.splice(newSubCategories.findIndex(item => item.id === rowId), 1)
      setSubCategories(newSubCategories)
    } catch(error) {
      notification.error({
        message: 'Sub Category Error',
        description: 'There was a problem deleting this sub category. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Button type="default" size="small" onClick={() => updateRow(row)}>
              <EditOutlined /> 
            </Button>
            <Popconfirm placement="right" title={'Are you sure you want to delete this sub category?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            {
              row.image_url ? <img src={`${process.env.REACT_APP_API_URL}documents/download/${row.image_url}?t=${new Date().getTime()}&access_token=${TokenStorage.getToken()}`} alt="" width={40} height={40} /> : <Skeleton.Image style={{width: 40, height: 40}} />
            }
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
  ];

  const updateRow = (item) => {
    setSelectedItem(item);
    setShowAdd(true);
  }

  const handleMainLayoutClick = (type) => {
    if(type === 'CREATE_NEW') {
      setSelectedItem(null);
      setShowAdd(true);
    } else if (type === 'GO_BACK') {
      props.history.push(PageRoutes.Categories);
    }
  }

  const handleNameOnClick = async (type, value, item, files, translations) => {
    const translationDictionary = {};

    translations && translations.forEach(item => {
      translationDictionary[item.language] = item.translation;
    });

    if(type === 'cancel') {
      setShowAdd(false);
    } else if(type === 'add') {
      const { data } = await Api.post('categories/create', { name: value, parent_id: parseInt(categoryId), name_translations: translationDictionary });
     
      if(data && data.id > 0) {
        if(files.length > 0) {
          files.map(async (item) => {
            var img = URL.createObjectURL(item);
            fetch(img)
            .then(async (res) => res.blob())
            .then(async (blob) => {
              var options = {
                method: 'POST',
                headers: {
                  "Api-Arg": JSON.stringify({
                    name: item.name,
                    file_type: Utils.getFileExtension(item.name)
                  }),
                  "Content-Type": item.type,
                  "Authorization": `JWT ${TokenStorage.getToken()}`
                },
                data: blob,
                url: `${process.env.REACT_APP_API_URL}categories/${data.id}/upload_image`
              }

              try {
                const { data } = await axios(options);

                notification.success({
                  message: 'Sub Category Success',
                  description: 'The sub category has been created successfully!',
                  placement: 'bottomRight'
                });
                setShowAdd(false);
                retrieveCategories();

              } catch(error) {
                console.log(error);
              }
            });
          });
        } else {
          notification.success({
            message: 'Sub Category',
            description: 'The sub category has been created successfully!',
            placement: 'bottomRight'
          });
          setShowAdd(false);
          retrieveCategories();
        }

      } else {
        notification.error({
          message: 'Sub Category Error',
          description: 'There was a problem creating this sub category. Please check it has not already been created otherwise contact support.',
          placement: 'bottomRight'
        })
      }
    } else if(type === 'update') {
      try {
        const productId = item.id;
        const { data } = await Api.put(`categories/${productId}/update`, { name: value, name_translations: translationDictionary });

        if(files.length > 0) {
          files.map(async (productItem) => {
            var img = URL.createObjectURL(productItem);
            fetch(img)
            .then(async (res) => res.blob())
            .then(async (blob) => {
              var options = {
                method: 'POST',
                headers: {
                  "Api-Arg": JSON.stringify({
                    name: productItem.name,
                    file_type: Utils.getFileExtension(productItem.name)
                  }),
                  "Content-Type": productItem.type,
                  "Authorization": `JWT ${TokenStorage.getToken()}`
                },
                data: blob,
                url: `${process.env.REACT_APP_API_URL}categories/${productId}/upload_image`
              }

              try {
                const { data } = await axios(options);

                notification.success({
                  message: 'Sub Category Success',
                  description: 'The sub category has been updated successfully!',
                  placement: 'bottomRight'
                });
      
                // updateSubCategories(productItem, value);
                setShowAdd(false);
                retrieveCategories();

              } catch(error) {
                console.log(error);
              }
            });
          });
        } else {
          notification.success({
            message: 'Sub Category Success',
            description: 'The sub category has been updated successfully!',
            placement: 'bottomRight'
          });

          // updateSubCategories(item, value);
          setShowAdd(false);
          retrieveCategories();
        }
      } catch(error) {
        notification.error({
          message: 'Sub Category Error',
          description: 'There was a problem updating this sub category. Please try again or contact support.',
          placement: 'bottomRight'
        })
      }
    }
  }

  return (
    <MainLayout pageTitle={'Sub Categories'} pageIconTitle={'sub_categories'} onClick={(type) => handleMainLayoutClick(type)} {...props}>
      <Row style={{marginBottom: 20}}>
        <Col>
            <Tag color="geekblue">{category ? category.name : ''}</Tag>
        </Col>
      </Row>
      <Table dataSource={subCategories} columns={columns} showSorterTooltip={false} rowKey="id" pagination={{ pageSize: 100 }}>
      </Table>
      {
        showAdd ? <AddDialog 
          existingItem={selectedItem}
          showFileUploader={true}
          modalTitle={"Add new sub category"} 
          nameLabel={"Name of sub category"} 
          placeholderDescription={"Enter name"} 
          errorTitle={"Sub Category Error"} 
          errorDescription={"The sub category name needs to be entered."}
          showNameTranslation={true}
          onAddClick={(type, value, item, files, translations) => handleNameOnClick(type, value, item, files, translations)} /> : null
      }
    </MainLayout>
  )
}

export default SubCategories