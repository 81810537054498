const PREFIX_PATH = "";

export default {
  Login:                `${PREFIX_PATH}/`,
  ForgotPassword:       `${PREFIX_PATH}/forgot_password`,
  ResetPassword:        `${PREFIX_PATH}/reset_password`,
  Activate:             `${PREFIX_PATH}/activate`,
  Landing:              `${PREFIX_PATH}/portal`,
  ActiveIngredients:    `${PREFIX_PATH}/portal/settings/active_ingredients`,
  Brands:               `${PREFIX_PATH}/portal/settings/brands`,
  Categories:           `${PREFIX_PATH}/portal/settings/categories`,
  Filters:              `${PREFIX_PATH}/portal/settings/filters`,
  SelectCategory:       `${PREFIX_PATH}/portal/settings/select_category`,
  SubCategories:        `${PREFIX_PATH}/portal/settings/categories/:categoryId/sub_categories`,
  Countries:            `${PREFIX_PATH}/portal/settings/countries`,
  Products:             `${PREFIX_PATH}/portal/products`,
  ProductCreate:        `${PREFIX_PATH}/portal/products/create`,
  ProductEdit:          `${PREFIX_PATH}/portal/products/:productId/edit`,
  Users:                `${PREFIX_PATH}/portal/users`,
  UserCreate:           `${PREFIX_PATH}/portal/users/create`,
  UserEdit:             `${PREFIX_PATH}/portal/users/:userId/edit`,
  Farmers:              `${PREFIX_PATH}/portal/farmers`,
  FarmerCreate:         `${PREFIX_PATH}/portal/farmers/create`,
  FarmerEdit:           `${PREFIX_PATH}/portal/farmers/:userId/edit`,
  Reports:              `${PREFIX_PATH}/portal/reports`,
};
