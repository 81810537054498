import React, { useEffect, useState } from 'react';

import { BarcodeOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Popconfirm, Row, Table } from "antd";

function ProductSerialNumber(props) {
  const [serialNumber, setSerialNumber] = useState('');
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if(props.existingNumbers) {
      setSerialNumbers(props.existingNumbers);
    }
  }, [props.existingNumbers])

  const addItem = () => {
    setError('');

    let tempSerialNumbers  = [...serialNumbers];
    let serialNumberText = serialNumber.trim();

    if(serialNumberText.length === 0) {
      setError('The serial number is required.');
    } else {
      let existingSerialNumber = tempSerialNumbers && tempSerialNumbers.filter((item) => { return item === serialNumberText });

      if(existingSerialNumber.length === 0) {
        tempSerialNumbers.push({
          id: tempSerialNumbers.length + 1,
          serial_number: serialNumberText
        });

        setSerialNumber('');
      } else {
        setError('The serial number already exists.')
      }
    }

    setSerialNumbers(tempSerialNumbers);
    props.serialNumbersUpdated(tempSerialNumbers);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Popconfirm placement="right" title={'Are you sure you want to delete this serial number?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      key: 'serial_number',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.serial_number.toUpperCase() > b.serial_number.toUpperCase()) cmp = 1;
        else if(a.serial_number.toUpperCase() < b.serial_number.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
  ];

  const deleteRow = (id) => {
    let tempSerialNumbers = [...serialNumbers];
    let filteredSerialNumbers = tempSerialNumbers.filter((item) => { return item.id !== id });

    setSerialNumbers(filteredSerialNumbers);

    props.serialNumbersUpdated(filteredSerialNumbers);
  }

  return (
    <Col span={24}>
      <Form
        name="item_form"
        className=""
        style={{minHeight: 150, textAlign: 'left'}}
        layout="vertical"
        onFinish={() => { return false; }}
      >
        <Form.Item label={"Enter serial number *"}>
          <Input 
            name="translation" 
            prefix={<BarcodeOutlined style={{paddingRight: 10}} />} 
            value={serialNumber} 
            style={{ width: '50%' }}
            onChange={(e) => setSerialNumber(e.target.value)} 
            onPressEnter={() => addItem()} />
        </Form.Item>
        <Button key="add" onClick={() => addItem()}>
          Add Serial Number
        </Button>
        {
          error && error.length > 0 ? <Row style={{paddingTop: 6, color: '#F00'}}>
            <Col span={24}>
              {error}
            </Col>
          </Row> : null
        }
      </Form>
      <Table dataSource={serialNumbers} columns={columns} showSorterTooltip={false} rowKey="id">
      </Table>
    </Col>
  )
}

export default ProductSerialNumber