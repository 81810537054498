import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Popconfirm, Row, Skeleton, Table } from 'antd';
import MainLayout from '../../common/MainLayout';
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import AddDialog from '../../forms/AddDialog';
import Api from '../../../services/Api';
import PageRoutes from '../../../services/PageRoutes';
import Utils from '../../common/Utils';
import TokenStorage from '../../../services/TokenStorage';

function ListProducts(props) {
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 50

  useEffect(() => {
    let cancel = false;

    const retrieveProducts = async () => {
      try {
        const offset = (page - 1) * PAGE_SIZE;
        const { data } = await Api.get(`products/list?limit=${PAGE_SIZE}&offset=${offset}`);

        if(cancel) {
          return;
        }

        setProducts(data.products);

        if (data.products && data.products.length > 0) {
          setTotalProducts(data.products[0].total);
        }
      } catch(error) {

      }
    }
    
    retrieveProducts();

    return () => {
      cancel = true;
    }
  }, [page]);

  const deleteRow = async (rowId) => {
    try {
      await Api.delete(`products/${rowId}/delete`);

      const newProducts = [...products];
      newProducts.splice(newProducts.findIndex(item => item.id === rowId), 1)
      setProducts(newProducts)
    } catch(error) {
      notification.error({
        message: 'Products Error',
        description: 'There was a problem deleting this product. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const navigateToProduct = (item) => {
    let fields = {
      ":productId": item.id
    }

    let path = PageRoutes.ProductEdit;
    let final_path = Utils.getParameterization(path, fields);

    props.history.push(final_path);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Button type="default" size="small" onClick={() => navigateToProduct(row)}>
              <EditOutlined /> 
            </Button>
            <Popconfirm placement="right" title={'Are you sure you want to delete this product?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            {
              row.image_url ? <img src={`${process.env.REACT_APP_API_URL}documents/download/${row.image_url}?t=${new Date().getTime()}&access_token=${TokenStorage.getToken()}`} alt="" width={40} height={40} /> : <Skeleton.Image style={{width: 40, height: 40}} />
            }
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      key: 'summary',
    },
  ];

  const handleCreateNew = () => {
    props.history.push(PageRoutes.ProductCreate);
  }

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  }

  return (
    <MainLayout pageTitle={'Products'} pageIconTitle={'products'} onClick={() => handleCreateNew()} hideBackButton={true} {...props}>
      <Table dataSource={products} columns={columns} showSorterTooltip={false} rowKey="id" pagination={{ pageSize: PAGE_SIZE, total: totalProducts, onChange: handlePageChange }}>
      </Table>
    </MainLayout>
  )
}

export default ListProducts