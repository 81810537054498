import React, { useEffect, useState } from 'react';
import { Button, Col, notification, Popconfirm, Row, Table } from 'antd';
import MainLayout from '../../common/MainLayout';
import { DeleteOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import AddDialog from '../../forms/AddDialog';
import Api from '../../../services/Api';

function Brands(props) {
  const [showAdd, setShowAdd] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    let cancel = false;

    const retrieveBrands = async () => {
      const { data } = await Api.get('brands/list');

      if(cancel) {
        return;
      }

      setBrands(data.brands);
    }
    
    retrieveBrands();

    return () => {
      cancel = true;
    }
  }, []);

  const deleteRow = async (rowId) => {
    try {
      await Api.delete(`brands/${rowId}/delete`);

      const newBrands = [...brands];
      newBrands.splice(newBrands.findIndex(item => item.id === rowId), 1)
      setBrands(newBrands)
    } catch(error) {
      notification.error({
        message: 'Brand Error',
        description: 'There was a problem deleting this brand. Please try again or contact support.',
        placement: 'bottomRight'
      });
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (text, row, index) => {
        return <Row>
          <Col>
            <Button type="default" size="small" onClick={() => updateRow(row)}>
              <EditOutlined /> 
            </Button>
            <Popconfirm placement="right" title={'Are you sure you want to delete this brand?'} onConfirm={() => deleteRow(row.id)} okText="YES" cancelText="NO" icon={<WarningOutlined />}>
              <Button type="danger" size="small" style={{marginLeft: 10}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => { 
        let cmp = 0;

        if(a.name.toUpperCase() > b.name.toUpperCase()) cmp = 1;
        else if(a.name.toUpperCase() < b.name.toUpperCase()) cmp = -1;

        return cmp;
      },
    },
  ];

  const updateRow = (item) => {
    setSelectedItem(item);
    setShowAdd(true);
  }

  const handleCreateNew = () => {
    setSelectedItem(null);
    setShowAdd(true);
  }

  const updateBrands = (item, name) => {
    let filteredCurrentBrands = [...brands];

    for(var i = 0; i < filteredCurrentBrands.length; i++) {
      if(filteredCurrentBrands[i].id === item.id) {
        filteredCurrentBrands[i].name = name;
        break;
      }
    }

    setBrands(filteredCurrentBrands);
  }

  const handleNameOnClick = async (type, value, item) => {
    if(type === 'cancel') {
      setShowAdd(false);
    } else if(type === 'add') {
      const { data } = await Api.post('brands/create', { name: value });
     
      if(data && data.id > 0) {
        notification.success({
          message: 'Brand Success',
          description: 'The brand has been created successfully!',
          placement: 'bottomRight'
        });
        setShowAdd(false);
        setBrands([...brands, data]);
      } else {
        notification.error({
          message: 'Brand Error',
          description: 'There was a problem creating this brand. Please check it has not already been created otherwise contact support.',
          placement: 'bottomRight'
        })
      }
    } else if(type === 'update') {
      try {
        const { data } = await Api.put(`brands/${item.id}/update`, { name: value });
        notification.success({
          message: 'Brand Success',
          description: 'The brand has been updated successfully!',
          placement: 'bottomRight'
        });
        
        updateBrands(item, value);
        setShowAdd(false);

      } catch(error) {
        notification.error({
          message: 'Brand Error',
          description: 'There was a problem updating this brand. Please try again or contact support.',
          placement: 'bottomRight'
        })
      }
    }
  }

  return (
    <MainLayout pageTitle={'Brands'} pageIconTitle={'brands'} onClick={() => handleCreateNew()} {...props}>
      <Table dataSource={brands} columns={columns} showSorterTooltip={false} rowKey="id" pagination={{ pageSize: 100 }}>
      </Table>
      {
        showAdd ? <AddDialog 
          existingItem={selectedItem}
          modalTitle={"Add new brand"} 
          nameLabel={"Name of brand"} 
          placeholderDescription={"Enter name"} 
          errorTitle={"Brand Error"} 
          errorDescription={"The brand name needs to be entered."}
          onAddClick={(type, value, item) => handleNameOnClick(type, value, item)} /> : null
      }
    </MainLayout>
  )
}

export default Brands